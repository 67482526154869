import React, { useState, useEffect } from 'react';
import axiosClient from './AxiosClient';

const EditPopup = ({ productId,name, onClose }) => {
  const [productData, setProductData] = useState({});
  const [editedProduct, setEditedProduct] = useState({ facts: [] }); // Initialize facts as an empty array
  const [formDataToSend, setFormDataToSend] = useState(null);

  useEffect(() => {
    const fetchProduct = async () => {
      try {
        const adminToken = localStorage.getItem('adminToken');
        
        if (adminToken) {
          const formData = new FormData();
          formData.append('productId', productId);
          formData.append("name", name)
          formData.append('pincode', '201001');

          const response = await axiosClient.post(`/products/get_product/${productId}`, formData, {
            headers: {
              'Authorization': `Bearer ${adminToken}`,
            },
          });

          const productData = response.data.data[0];
          setProductData(productData);
          setEditedProduct({ ...productData, facts: productData.facts || [] }); // Ensure facts is initialized

          const formDataToSend = new FormData();
          Object.entries(productData).forEach(([key, value]) => {
            formDataToSend.append(key, value);
          });
          setFormDataToSend(formDataToSend);
        } else {
          console.error('Admin token not found in local storage');
        }
      } catch (error) {
        console.error('Error fetching product:', error);
      }
    };

    fetchProduct();
  }, [productId]);
  console.log(productData)
  const handleFieldChange = (e) => {
    const { name, value } = e.target;
    setEditedProduct((prevState) => ({
      ...prevState,
      [name]: value,
    }));

    const updatedFormData = new FormData();
    Object.entries({ ...editedProduct, [name]: value }).forEach(([key, value]) => {
      updatedFormData.append(key, value);
    });
    setFormDataToSend(updatedFormData);
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    setEditedProduct((prevState) => ({
      ...prevState,
      image: file,
    }));

    const updatedFormData = new FormData(formDataToSend);
    updatedFormData.set('image', file);
    setFormDataToSend(updatedFormData);
  };

  const handleFactsChange = (index, newValue) => {
    const updatedFacts = [...editedProduct.facts];
    updatedFacts[index] = newValue;
    setEditedProduct((prevState) => ({
      ...prevState,
      facts: updatedFacts,
    }));
    
    // Update the entire facts array in formDataToSend
    const updatedFormData = new FormData();
    Object.entries({ ...editedProduct, facts: updatedFacts }).forEach(([key, value]) => {
      updatedFormData.append(key, value);
    });
    setFormDataToSend(updatedFormData);
  };
  
  const handleAddFact = async () => {
    setEditedProduct((prevState) => {
      const newFacts = [...prevState.facts, '']; // Add an empty string for the new fact
      // Update the entire facts array in formDataToSend
      const updatedFormData = new FormData();
      Object.entries({ ...prevState, facts: newFacts }).forEach(([key, value]) => {
        updatedFormData.append(key, value);
      });
      setFormDataToSend(updatedFormData);
      
      // Call API to add the FAQ
      addFaq(newFacts.length - 1); // Pass the index of the new FAQ
      return {
        ...prevState,
        facts: newFacts,
      };
    });
  };
  
  const addFaq = async (index) => {
    try {
      const adminToken = localStorage.getItem('adminToken');
      if (!adminToken) {
        console.error('Admin token not found in local storage');
        return;
      }
  
      const question = editedProduct.faq[index]?.question;
      const answer = editedProduct.faq[index]?.answer;
  
      const response = await axiosClient.post(`/products/add_faq/${productId}`, {
        question,
        answer
      }, {
        headers: {
          Authorization: `Bearer ${adminToken}`,
        },
      });
  
      console.log('FAQ added successfully:', response.data);
    } catch (error) {
      console.error('Error adding FAQ:', error);
    }
  };
  
  const handleRemoveFact = async (index) => {
    const updatedFacts = editedProduct.facts.filter((_, i) => i !== index);
    setEditedProduct((prevState) => {
      const updatedFormData = new FormData();
      Object.entries({ ...prevState, facts: updatedFacts }).forEach(([key, value]) => {
        updatedFormData.append(key, value);
      });
      setFormDataToSend(updatedFormData);
  
      // Call API to delete the FAQ
      deleteFaq(index);
      return {
        ...prevState,
        facts: updatedFacts,
      };
    });
  };
  
  const deleteFaq = async (index) => {
    try {
      const adminToken = localStorage.getItem('adminToken');
      if (!adminToken) {
        console.error('Admin token not found in local storage');
        return;
      }
  
      const response = await axiosClient.post(`/products/delete_faq/${productId}/${index}`, {}, {
        headers: {
          Authorization: `Bearer ${adminToken}`,
        },
      });
  
      console.log('FAQ deleted successfully:', response.data);
    } catch (error) {
      console.error('Error deleting FAQ:', error);
    }
  };
  
  const handleFaqChange = async (index, key, value) => {
    const updatedFaq = [...editedProduct.faq];
    updatedFaq[index] = { ...updatedFaq[index], [key]: value };
    setEditedProduct((prevState) => ({
      ...prevState,
      faq: updatedFaq,
    }));
  
    // Call API to edit the FAQ
    await editFaq(index, updatedFaq[index]);
  };
  
  const editFaq = async (index, faq) => {
    try {
      const adminToken = localStorage.getItem('adminToken');
      if (!adminToken) {
        console.error('Admin token not found in local storage');
        return;
      }
  
      const { question, answer } = faq;
      const response = await axiosClient.post(`/products/edit_faq/${productId}/${index}`, {
        question,
        answer
      }, {
        headers: {
          Authorization: `Bearer ${adminToken}`,
        },
      });
  
      console.log('FAQ edited successfully:', response.data);
    } catch (error) {
      console.error('Error editing FAQ:', error);
    }
  };
  

  const handleSubmit = async () => {
    try {
      const adminToken = localStorage.getItem('adminToken');
      if (!adminToken) {
        console.error('Admin token not found in local storage');
        return;
      }
  
      const updatedFormData = new FormData();
      
      // Add all edited product fields to the form data
      Object.entries(editedProduct).forEach(([key, value]) => {
        if (productData[key] !== value) {
          updatedFormData.append(key, value);
        }
      });
  
      // Send null for facts if the array is empty
      if (editedProduct.facts.length === 0) {
        updatedFormData.append('facts', null); // Send null for facts
      } else {
        // Append each fact to the FormData
        editedProduct.facts.forEach((fact) => {
          updatedFormData.append('facts', fact);
        });
      }
  
      const response = await axiosClient.post(`/products/edit_product/${productId}`, updatedFormData, {
        headers: {
          Authorization: `Bearer ${adminToken}`,
        },
      });
      console.log('Product updated successfully:', response.data);
  
      window.location.reload();
    } catch (error) {
      console.error('Error updating product:', error);
    }
  };
  

  return (
    <div className="fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-96 bg-white rounded-lg p-8 overflow-y-auto shadow-lg max-h-screen">
      <button className="absolute top-0 right-0 px-3 py-1 text-gray-600 hover:text-gray-800" onClick={onClose}>
        &times;
      </button>
      <h2 className="text-xl font-semibold mb-4">Edit Product</h2>
      {productData && Object.keys(productData).length > 0 ? (
        <div>
          {Object.entries(productData).map(([key, value]) => (
            key !== 'id' && (
              <div key={key} className="mb-4">
                <label className="font-semibold">{key}</label>
                {key === 'image' ? (
                  <input
                    type="file"
                    name={key}
                    onChange={handleImageChange}
                    className="block w-full px-3 py-2 mt-1 border rounded-md focus:outline-none focus:ring focus:ring-blue-500"
                  />
                ) : key === 'facts' || key === 'benefits' || key === 'side_effects'? (
                  <div>
                    {editedProduct[key].map((fact, index) => (
                      <div key={index} className="flex items-center mb-2">
                        <input
                          type="text"
                          value={fact}
                          onChange={(e) => handleFactsChange(index, e.target.value)}
                          className="block w-full px-3 py-2 border rounded-md focus:outline-none focus:ring focus:ring-blue-500"
                        />
                        <button onClick={() => handleRemoveFact(index)} className="ml-2 px-2 py-1 bg-red-500 text-white rounded-md">
                          Remove
                        </button>
                      </div>
                    ))}
                    <button onClick={handleAddFact} className="px-3 py-1 bg-blue-500 text-white rounded-md">
                      Add Fact
                    </button>
                  </div>
                ) : key === 'faq' ? (
                  value.map((obj, index) => (
                    <div key={index} className="mb-4">
                      <div className="mb-2">
                        <label className="font-semibold">Question</label>
                        <input
                          type="text"
                          value={obj.question || ''}
                          onChange={(e) => handleFaqChange(index, 'question', e.target.value)}
                          className="block w-full px-3 py-2 border rounded-md focus:outline-none focus:ring focus:ring-blue-500"
                        />
                      </div>
                      <div className="mb-2">
                        <label className="font-semibold">Answer</label>
                        <input
                          type="text"
                          value={obj.answer || ''}
                          onChange={(e) => handleFaqChange(index, 'answer', e.target.value)}
                          className="block w-full px-3 py-2 border rounded-md focus:outline-none focus:ring focus:ring-blue-500"
                        />
                      </div>
                    </div>
                  ))
                ) : (
                  <input
                    type="text"
                    name={key}
                    value={editedProduct[key] || ''}
                    onChange={handleFieldChange}
                    className="block w-full px-3 py-2 mt-1 border rounded-md focus:outline-none focus:ring focus:ring-blue-500"
                  />
                )}
              </div>
            )
          ))}
        </div>
      ) : (
        <p>Loading product details...</p>
      )}
      <div className="flex justify-end mt-4">
        <button className="px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600" onClick={handleSubmit}>
          Save
        </button>
        <button className="ml-4 px-4 py-2 bg-red-500 text-white rounded-md hover:bg-red-600" onClick={onClose}>
          Cancel
        </button>
      </div>
    </div>
  );
};

export default EditPopup;
