import React, { useState , useEffect} from 'react';
import { Link } from 'react-router-dom';
import { AiFillMedicineBox } from 'react-icons/ai';
import { BsPeopleFill } from 'react-icons/bs';
import { IoPersonAdd } from 'react-icons/io5';
import { MdOutlineMessage } from 'react-icons/md';
import { BsQuestionCircleFill } from 'react-icons/bs';
import { RiToolsFill } from 'react-icons/ri';
import { GiMedicines } from 'react-icons/gi';
import { BiSolidCoupon } from 'react-icons/bi';
import { TfiWrite } from 'react-icons/tfi';
import { RiLogoutCircleLine } from 'react-icons/ri';
import { MdOutlineDiscount } from 'react-icons/md';
import { IoCreate } from 'react-icons/io5';
import { FaUserGroup } from 'react-icons/fa6';
import { FaUpload } from 'react-icons/fa6';
import { FaBars } from 'react-icons/fa'; // Add this import
import { FaTrophy } from 'react-icons/fa';






const LeftSection = ({ selectedOption, handleOptionClick }) => {
  const [logoutMessage, setLogoutMessage] = useState('');
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [showHamburger, setShowHamburger] = useState(true); // State to track hamburger visibility

  const handleLogout = () => {
    localStorage.removeItem('adminToken');
    setLogoutMessage('Logout Successful!');
    setTimeout(() => {
      
    }, 2000);
  };

  const toggleSidebar = () => {
    setIsSidebarOpen((prev) => !prev);
  };

  // Scroll event handler
  const handleScroll = () => {
    if (window.scrollY > 50) { // Adjust the scroll threshold as needed
      setShowHamburger(false);
    } else {
      setShowHamburger(true);
    }
  };

  // Attach scroll event listener on mount and cleanup on unmount
  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <>
      { showHamburger && (
      <button
        onClick={toggleSidebar}
        className="lg:hidden p-4 fixed top--1.2 left-1 z-50 bg-orange-500 text-white rounded-md focus:outline-none"
      >
        <FaBars size={24} />
      </button>
      )}

      {/* Sidebar */}
      <div
        className={`fixed top-0 left-0 h-full w-64 overflow-y-auto bg-[linear-gradient(90deg,#FB8802_0%,#FFE649_99.99%)] transition-transform duration-300 transform ${
          isSidebarOpen ? 'translate-x-0' : '-translate-x-full'
        } lg:translate-x-0 lg:w-1/6 z-40 p-4 font-['Lexend']`}
      >
        <h2 className="text-sm sm:text-base md:text-lg lg:text-xl font-bold mb-4 text-black sm:text-base text-sm sm:ml-0 ml-[-12px]">Options</h2>
        {logoutMessage && (
          <div className="mb-4 text-green-500 font-bold">{logoutMessage}</div>
        )}
        <ul className="text-[18px]">
          <li className="mb-2 duration-300 transform hover:scale-105 rounded-lg hover:bg-orange-400 hover:text-white">
            <Link
              to="/admin/products"
              className={`${
                selectedOption === 'products' ? 'text-white' : 'text-gray-200'
              } flex items-center p-2`}
              onClick={() => handleOptionClick('products')}
            >
              <GiMedicines /> &nbsp; All Product
            </Link>
          </li>
          <li className="mb-2 duration-300 transform hover:scale-105 rounded-lg hover:bg-orange-400 hover:text-white">
            <Link
              to="/admin/addproduct"
              className={`${
                selectedOption === 'addproducts' ? 'text-white' : 'text-gray-200'
              } flex items-center p-2`}
              onClick={() => handleOptionClick('addproducts')}
            >
              <AiFillMedicineBox /> &nbsp; Add Product
            </Link>
          </li>
          <li className="mb-2 duration-300 transform hover:scale-105 rounded-lg hover:bg-orange-400 hover:text-white">
            <Link
            to="/admin/coupons"
            className={`${selectedOption === 'coupons' ? 'text-white' : 'text-gray-200'} flex items-center p-2`}
            onClick={() => handleOptionClick('coupons')}
            >
              <BiSolidCoupon /> &nbsp; Coupons
            </Link>
            </li>
          <li className="mb-2 duration-300 transform hover:scale-105 rounded-lg hover:bg-orange-400 hover:text-white">
            <Link
            to="/admin/blogs"
            className={`${selectedOption === 'blogs' ? 'text-white' : 'text-gray-200'} flex items-center p-2`}
            onClick={() => handleOptionClick('blogs')}
            >
              <TfiWrite /> &nbsp; Blogs
            </Link>
            </li>
            <li className="mb-2 duration-300 transform hover:scale-105 rounded-lg hover:bg-orange-400 hover:text-white">
              <Link
              to="/admin/users"
              className={`${selectedOption === 'about' ? 'text-white' : 'text-gray-200'} flex items-center p-2`}
              onClick={() => handleOptionClick('about')}
              >
              < BsPeopleFill /> &nbsp; Users 
              </Link>
            </li>

            <li className="mb-2 duration-300 transform hover:scale-105 rounded-lg hover:bg-orange-400 hover:text-white">
              <Link
              to="/admin/queries"
              className={`${selectedOption === 'queries' ? 'text-white' : 'text-gray-200'} flex items-center p-2`}
              onClick={() => handleOptionClick('queries')}
              >
                <MdOutlineMessage /> &nbsp; Help Queries
              </Link>
            </li>

            <li className="mb-2 duration-300 transform hover:scale-105 rounded-lg hover:bg-orange-400 hover:text-white">
              <Link
              to="/admin/vendor-requests"
              className={`${selectedOption === 'vendor-requests' ? 'text-white' : 'text-gray-200'} flex items-center p-2`}
              onClick={() => handleOptionClick('vendor-requests')}
              >
               <IoPersonAdd /> &nbsp; Vendor Requests 
              </Link>
            </li>


            <li className="mb-2 duration-300 transform hover:scale-105 rounded-lg hover:bg-orange-400 hover:text-white">
            <Link
            to="/admin/allvendors"
            className={`${selectedOption === 'allvendors' ? 'text-white' : 'text-gray-200'} flex items-center p-2`}
            onClick={() => handleOptionClick('allvendors')}
            >
             <IoPersonAdd /> &nbsp; All Vendors 
            </Link>
            </li>

            <li className="mb-2 duration-300 transform hover:scale-105 rounded-lg hover:bg-orange-400 hover:text-white">
            <Link
            to="/admin/faq"
            className={`${selectedOption === 'faq' ? 'text-white' : 'text-gray-200'} flex items-center p-2`}
            onClick={() => handleOptionClick('faq')}
            >
             <BsQuestionCircleFill /> &nbsp; FAQs 
            </Link>
            </li>


            <li className="mb-2 duration-300 transform hover:scale-105 rounded-lg hover:bg-orange-400 hover:text-white">
            <Link
            to="/admin/testimonial"
            className={`${selectedOption === 'testimonial' ? 'text-white' : 'text-gray-200'} flex items-center p-2`}
            onClick={() => handleOptionClick('testimonial')}
            >
             <RiToolsFill /> &nbsp; Testimonials 
            </Link>
            </li>

            <li className="mb-2 duration-300 transform hover:scale-105 rounded-lg hover:bg-orange-400 hover:text-white">
            <Link
            to="/admin/diffqueries"
            className={`${selectedOption === 'queries' ? 'text-white' : 'text-gray-200'} flex items-center p-2`}
            onClick={() => handleOptionClick('diffqueries')}
            >
             <MdOutlineMessage /> &nbsp; Queries 
            </Link>
            </li>


            <li className="mb-2 duration-300 transform hover:scale-105 rounded-lg hover:bg-orange-400 hover:text-white">
            <Link
            to="/admin/allsmartdeals"
            className={`${selectedOption === 'queries' ? 'text-white' : 'text-gray-200'} flex items-center p-2`}
            onClick={() => handleOptionClick('allsmartdeals')}
            >
              <MdOutlineDiscount /> &nbsp; Smart Deals
              </Link> 
              </li>

              <li className="mb-2 duration-300 transform hover:scale-105 rounded-lg hover:bg-orange-400 hover:text-white">
          <Link
            to="/admin/createsmartdeal"
            className={`${selectedOption === 'queries' ? 'text-white' : 'text-gray-200'} flex items-center p-2`}
            onClick={() => handleOptionClick('allsmartdeals')}
            >
              <IoCreate /> &nbsp; Create Deal
              </Link> 
              </li>

              <li className="mb-2 duration-300 transform hover:scale-105 rounded-lg hover:bg-orange-400 hover:text-white">
          <Link
            to="/admin/alliances"
            className={`${selectedOption === 'queries' ? 'text-white' : 'text-gray-200'} flex items-center p-2`}
            onClick={() => handleOptionClick('alliances')}
            >
              <FaUserGroup /> &nbsp; Alliances
              
              </Link> 
            </li>

            <li className="mb-2 duration-300 transform hover:scale-105 rounded-lg hover:bg-orange-400 hover:text-white">
          <Link
            to="/admin/uploadproducts"
            className={`${selectedOption === 'queries' ? 'text-white' : 'text-gray-200'} flex items-center p-2`}
            onClick={() => handleOptionClick('uploadProducts')}
            >
              <FaUpload /> &nbsp; Upload Products
            </Link>
            
            



            
























              

              



            
              


            
          </li>
          <li className="mb-2 duration-300 transform hover:scale-105 rounded-lg hover:bg-orange-400 hover:text-white">
  <Link
    to="/admin/smartchamp"
    className={`${selectedOption === 'smartchamp' ? 'text-white' : 'text-gray-200'} flex items-center p-2`}
    onClick={() => handleOptionClick('smartchamp')}
  >
    <FaTrophy /> &nbsp; SmartChamp
  </Link>
</li>
          {/* Add remaining menu options here following the same structure */}
          <li className="mb-2 duration-300 transform hover:scale-105 rounded-lg hover:bg-orange-400 hover:text-white">
            <Link
              to="/"
              className={`${
                selectedOption === 'logout' ? 'text-white' : 'text-gray-200'
              } flex items-center p-2`}
              onClick={handleLogout}
            >
              <RiLogoutCircleLine /> &nbsp; Logout
            </Link>
          </li>
        </ul>

        {/* Close Button for Mobile View */}
        <button
          onClick={toggleSidebar}
          className="lg:hidden p-2 fixed top-4 right-4 text-black bg-yellow-300 rounded-md focus:outline-none"
        >
          Close
        </button>
      </div>

      {/* Overlay for Mobile Sidebar */}
      {isSidebarOpen && (
        <div
          onClick={toggleSidebar}
          className="fixed inset-0 bg-black opacity-50 z-30 lg:hidden"
        />
      )}
    </>
  );
};

export default LeftSection;
