import React, { useState } from 'react';
import { GoBellFill } from 'react-icons/go';
import LeftSection from './HomeLeft';
import { Route, Routes, useNavigate } from 'react-router-dom'; // Import Routes and useNavigate

const Home = ({ children }) => {
  const [selectedOption, setSelectedOption] = useState('');
  const navigate = useNavigate();

  const handleOptionClick = (option) => {
    setSelectedOption(option);
    navigate(`/admin/${option}`);
  };

  return (
    <div className="flex h-screen font-['Lexend']"> {/* Apply Lexend font */}
      {/* Left Section */}
      <LeftSection
        className="w-full sm:w-1/6 fixed top-0 left-0 h-full overflow-y-auto bg-gradient-to-r from-[#FB8802] to-[#FFE649]" // Full width for mobile view and 1/6 for larger screens
        selectedOption={selectedOption}
        handleOptionClick={handleOptionClick}
      />

      {/* Right Section */}
      <div className="flex-1 lg:ml-64">
        <div className="h-14 sm:h-16 bg-gradient-to-r from-[#FB8802] to-[#FFE649] flex justify-end items-center pr-4 text-black text-xl sm:text-4xl"> {/* Adjust height and text size for mobile view */}
          <GoBellFill />
        </div>
        {/* Render the selected option */}
        <div className="p-4 text-black">
          <Routes>
            {children} {/* Render the child Routes */}
          </Routes>
        </div>
      </div>
    </div>
  );
};

export default Home;
