import React, { useContext, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import LoginPopup from './LoginPopup';
import adminContext from '../context/admin/adminContext';

const TokenExpiryChecker = () => {
  const { expiredToken, checkTokenExpiry } = useContext(adminContext);
  const location = useLocation();

  useEffect(() => {
    // Trigger token check whenever the route changes
    checkTokenExpiry();
  }, [location.pathname, checkTokenExpiry]); // Dependency array includes pathname

  if (location.pathname === '/') {
    return null; // Don't show the popup on the login page
  }

  return expiredToken ? <LoginPopup /> : null; // Show popup if token is expired
};

export default TokenExpiryChecker;
