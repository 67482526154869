import React, { useState } from 'react';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axiosClient from '../components/AxiosClient';

const UploadProducts = () => {
  const [selectedFile, setSelectedFile] = useState(null);
  const [isLoading, setIsLoading] = useState(false); // State to track loading status

  const handleFileChange = (e) => {
    setSelectedFile(e.target.files[0]);
  };

  const handleUpload = async (e) => {
    e.preventDefault();
    setIsLoading(true); // Set loading to true when starting upload

    try {
      const adminToken = localStorage.getItem('adminToken');
      if (!adminToken) {
        toast.error('Vendor token not found in local storage');
        setIsLoading(false); // Reset loading state on error
        return;
      }

      if (!selectedFile) {
        toast.error('No file selected');
        setIsLoading(false); // Reset loading state on error
        return;
      }

      const formData = new FormData();
      formData.append('file', selectedFile);

      const response = await axiosClient.post('/products/upload_products_admin', formData, {
        headers: {
          'Authorization': `Bearer ${adminToken}`,
          'Content-Type': 'multipart/form-data'
        }
      });

      if (response.status === 200) {
        toast.success('File uploaded successfully');
        setSelectedFile(null); // Reset the file selection after successful upload
      } else {
        toast.error('Failed to upload file');
      }
    } catch (error) {
      console.error('Error uploading file:', error);

      if (error.response) {
        toast.error(`Failed to upload file: ${error.response.data.message || 'Internal Server Error'}`);
      } else if (error.request) {
        toast.error('No response from server');
      } else {
        toast.error('Error setting up request');
      }
    }
    setIsLoading(false); // Reset loading state after upload attempt
  };

  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-white p-4 relative"> {/* Set background to white */}
      <form
        className={`bg-white p-6 rounded-lg shadow-lg w-full max-w-md transition-transform transform hover:scale-105 ${isLoading ? 'opacity-30' : ''}`}
        onSubmit={handleUpload}
      >
        <h2 className="text-2xl font-bold mb-4 text-center">Add Product</h2>
        <p className="text-gray-600 text-sm mb-4 text-center">
          Please upload a CSV or Excel file containing product information.
        </p>
        <div className="mb-4">
          <label className="block text-gray-700 text-sm font-bold mb-2">
            Upload CSV/Excel File
          </label>
          <input
            type="file"
            accept=".csv, .xlsx"
            onChange={handleFileChange}
            className={`w-full px-3 py-2 border rounded focus:outline-none focus:ring focus:ring-blue-300 transition duration-150 ${!selectedFile ? 'border-gray-300' : 'border-blue-500'}`}
          />
        </div>
        {selectedFile && (
          <div className="mb-4 text-center text-gray-700">
            <strong>Selected File:</strong> {selectedFile.name}
          </div>
        )}

        <button
          type="submit"
          className="w-full bg-blue-500 text-white py-2 rounded hover:bg-blue-600 transition duration-150"
          disabled={isLoading} // Disable button when loading
        >
          Add Product
        </button>
      </form>

      {/* Loader and Image in Center of the Screen */}
      {isLoading && (
        <div className="absolute inset-0 flex items-center justify-center bg-black opacity-80 z-30">
          <div className="relative flex items-center justify-center">
            <div className="loader" style={{
                width: '80px',
                height: '80px',
                border: '8px solid rgba(255, 255, 255, 0.8)', // Mostly white with transparency
                borderTop: '8px solid orange', // Orange spinner
                borderRadius: '50%',
                animation: 'spin 2s linear infinite', // Slower spin
            }} />
            <img 
              src="/loader.png" 
              alt="Loading..." 
              style={{
                width: '60px',
                height: '60px',
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
              }} 
            />
          </div>
        </div>
      )}

      <ToastContainer />
    </div>
  );
};

export default UploadProducts;
