import React, { useState, useEffect } from 'react';
import axiosClient from '../components/AxiosClient';

const EditTestimonial = ({ testimonial, onClose, onEdit }) => {
  const [user, setUser] = useState('');
  const [responseText, setResponseText] = useState('');
  const [link, setLink] = useState('');

  useEffect(() => {
    if (testimonial) {
      setUser(testimonial.user);
      setResponseText(testimonial.response);
      setLink(testimonial.link);
    }
  }, [testimonial]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const token = localStorage.getItem('adminToken');
      if (!token) {
        console.error('Token not found in localStorage');
        return;
      }

      const config = {
        headers: {
          'Authorization': `Bearer ${token}`,
        },
      };

      const formData = new FormData();
      formData.append('id', testimonial._id);
      formData.append('user_name', user);
      formData.append('response', responseText);
      formData.append('link', link);

      const editResponse = await axiosClient.post('/testimonial/edit_testimonial', formData, config);
      console.log('Edit Testimonial API response:', editResponse.data);

      if (editResponse) {
        onEdit(editResponse.data);
        onClose();
        window.location.reload();
      }
    } catch (error) {
      console.error('Error editing testimonial:', error);
    }
  };

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-50">
      <div className="bg-white p-8 rounded-md shadow-md w-1/3">
        <h2 className="text-lg font-semibold mb-4">Edit Testimonial</h2>
        <form onSubmit={handleSubmit}>
          <div className="mb-4">
            <label className="block text-gray-700">User</label>
            <input
              type="text"
              value={user}
              onChange={(e) => setUser(e.target.value)}
              className="w-full px-3 py-2 border rounded"
              required
            />
          </div>
          <div className="mb-4">
            <label className="block text-gray-700">Response</label>
            <textarea
              value={responseText}
              onChange={(e) => setResponseText(e.target.value)}
              className="w-full px-3 py-2 border rounded"
              required
            />
          </div>
          <div className="mb-4">
            <label className="block text-gray-700">Link</label>
            <input
              type="text"
              value={link}
              onChange={(e) => setLink(e.target.value)}
              className="w-full px-3 py-2 border rounded"
            />
          </div>
          <div className="flex justify-end">
            <button type="button" className="bg-gray-500 text-white px-4 py-2 rounded mr-2" onClick={onClose}>
              Cancel
            </button>
            <button type="submit" className="bg-blue-500 text-white px-4 py-2 rounded">
              Save
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default EditTestimonial;
