import React, { useState, useEffect, useMemo } from 'react';
import axios from 'axios';
import EditPopup from '../components/EditPopup'; 
import axiosClient from '../components/AxiosClient';

const Products = () => {
  const [products, setProducts] = useState([]);
  const [selectedProductId, setSelectedProductId] = useState(null); 
  const [isPopupOpen, setIsPopupOpen] = useState(false); 
  const [currentPage, setCurrentPage] = useState(1); 
  const [loading, setLoading] = useState(false); 
  const [hasMore, setHasMore] = useState(true); 
  const [sortOrder, setSortOrder] = useState('name-asc'); 
  const pageSize = 10; 

  const fetchProducts = async (page) => {
    try {
      setLoading(true);
      const adminToken = localStorage.getItem('adminToken');
      
      if (adminToken) {
        const response = await axiosClient.post(`/products/get_all_products/${page}/${pageSize}`, null, {
          headers: {
            'Authorization': `Bearer ${adminToken}`
          }
        });

        const newProducts = response.data.data[0];
        setProducts((prevProducts) => [...prevProducts, ...newProducts]);
        setHasMore(newProducts.length > 0); 
      } else {
        console.error('Admin token not found in local storage');
      }
      setLoading(false);
    } catch (error) {
      console.error('Error fetching products:', error);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchProducts(currentPage);
  }, [currentPage]);

  const handleSortChange = (e) => {
    setSortOrder(e.target.value);
  };

  const sortedProducts = useMemo(() => {
    console.log("Sorting order:", sortOrder); 

    return [...products].sort((a, b) => {
      switch (sortOrder) {
        case 'name-asc':
          return String(a.name).localeCompare(String(b.name));
        case 'name-desc':
          return String(b.name).localeCompare(String(a.name));
        case 'mrp':
          return a.mrp - b.mrp;
        case 'date':
          return new Date(b.created_at) - new Date(a.created_at); 
        default:
          return 0;
      }
    });
  }, [products, sortOrder]);

  const handleEditClick = (productId) => {
    setSelectedProductId(productId);
    setIsPopupOpen(true);
  };

  const handleDeleteClick = async (productId) => {
    try {
      const adminToken = localStorage.getItem('adminToken');
      if (!adminToken) {
        console.error('Admin token not found in local storage');
        return;
      }

      await axiosClient.post(`/products/delete_product/${productId}`, null, {
        headers: {
          'Authorization': `Bearer ${adminToken}`
        }
      });

      setProducts(products.filter(product => product.id !== productId));
    } catch (error) {
      console.error('Error deleting product:', error);
    }
  };

  const handleLoadMore = () => {
    setCurrentPage((prevPage) => prevPage + 1);
  };

  return (
    <div className="container mx-auto">
      <h2 className="text-2xl font-bold my-4">Products</h2>
      
      <div className="mb-4">
        <label htmlFor="sortOrder" className="mr-2">Sort By:</label>
        <select id="sortOrder" value={sortOrder} onChange={handleSortChange} className="border rounded p-1">
          <option value="name-asc">Name (A-Z)</option>
          <option value="name-desc">Name (Z-A)</option>
          <option value="mrp">MRP</option>
          <option value="date">Date Added</option>
        </select>
      </div>

      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6">
        {sortedProducts.map(product => (
          <div key={product.id} className="border rounded-lg shadow-md p-4 bg-white">
            <img src={product.image ? `data:image/jpeg;base64,${product.image.image_base64}` : 'placeholder.jpg'} alt="Product" className="w-full h-48 object-cover rounded-t" />
            <div className="mt-2">
              <h3 className="font-semibold">{product.name}</h3>
              <p className="text-gray-600">MRP: {product.mrp}</p>
              <div className="flex justify-between mt-4">
                <button onClick={() => handleEditClick(product.id)} className="text-blue-500 hover:underline">Edit</button>
                <button onClick={() => handleDeleteClick(product.id)} className="text-red-500 hover:underline">Delete</button>
              </div>
              <p className="text-gray-400 text-sm mt-2">ID: {product.id}</p>
            </div>
          </div>
        ))}
      </div>

      {isPopupOpen && (
        <EditPopup productId={selectedProductId} onClose={() => setIsPopupOpen(false)} />
      )}
      
      <div className="flex justify-center my-4">
        {loading && <span>Loading...</span>}
        {!loading && hasMore && (
          <button onClick={handleLoadMore} className="px-4 py-2 border rounded bg-blue-500 text-white hover:bg-blue-600">
            Load More
          </button>
        )}
        {!hasMore && (
          <span>No more products</span>
        )}
      </div>
    </div>
  );
};

export default Products;
