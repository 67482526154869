import React from 'react';

const ViewTestimonial = ({ testimonial, onClose }) => {
  if (!testimonial) return null;

  return (
    <div className="fixed inset-0 bg-gray-900 bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white p-8 rounded-md max-w-lg w-full">
        <h2 className="text-2xl font-semibold mb-4">{testimonial.user}'s Testimonial</h2>
        <p className="mb-4"><strong>Response:</strong> {testimonial.response}</p>
        <p className="mb-4"><strong>Link:</strong> {testimonial.link}</p>
        <div className="flex justify-end">
          <button
            className="bg-blue-500 text-white px-4 py-2 rounded"
            onClick={onClose}
          >
            Close
          </button>
        </div>
      </div>
    </div>
  );
};

export default ViewTestimonial;
