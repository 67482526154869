import React, { useState, useEffect } from "react";
import axiosClient from "../components/AxiosClient";

const Coupons = () => {
  const [couponsData, setCouponsData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [sortField, setSortField] = useState(""); // State for sorting and filtering
  const [selectedCoupon, setSelectedCoupon] = useState(null);

  useEffect(() => {
    const fetchCoupons = async () => {
      setIsLoading(true);
      try {
        const adminToken = localStorage.getItem("adminToken");
        const formData = new FormData();
        if (sortField === "used" || sortField === "unused") {
          formData.append("used", sortField === "used" ? "True" : "False");
        } else {
          formData.append("used", "None");
        }
        formData.append("order", "ascending"); // Default order

        const response = await axiosClient.post(
          "/coupons/get_coupons_admin",
          formData,
          {
            headers: {
              Authorization: `Bearer ${adminToken}`,
              "Content-Type": "multipart/form-data",
            },
          }
        );
        setCouponsData(response.data.data[0]);
      } catch (error) {
        console.error("Error fetching coupons:", error);
      }
      setIsLoading(false);
    };

    fetchCoupons();
  }, [sortField]);

  const handleSort = (field) => {
    if (field === "used" || field === "unused") {
      setSortField(field);
    } else {
      const sortedData = [...couponsData].sort((a, b) => {
        if (field === "created_at") {
          return new Date(a[field]) - new Date(b[field]);
        }
        if (typeof a[field] === "string") {
          return a[field].localeCompare(b[field]);
        }
        return 0;
      });
      setCouponsData(sortedData);
      setSortField(field);
    }
  };

  const handleViewDetails = (coupon) => {
    setSelectedCoupon(coupon);
  };

  const handleClosePopup = () => {
    setSelectedCoupon(null);
  };

  return (
    <div className="container mx-auto px-4 py-6 font-lexend text-black">
      <h1 className="text-3xl font-bold text-center mb-6">Coupons</h1>

      {/* Sort Dropdown */}
      <div className="mb-6 flex justify-between items-center">
        <div className="flex items-center gap-2">
          <label htmlFor="sort" className="font-semibold">
            Sort By:
          </label>
          <select
            id="sort"
            className="border border-gray-300 rounded-md px-3 py-2 focus:ring-2 focus:ring-yellow-400"
            onChange={(e) => handleSort(e.target.value)}
          >
            <option value="">Select</option>
            <option value="user_name">User Name</option>
            <option value="vendor_name">Vendor Name</option>
            <option value="created_at">Date Created</option>
            <option value="used">Used</option>
            <option value="unused">Not Used</option>
            <option value="all">All</option>
          </select>
        </div>
      </div>

      {/* Coupon Table */}
      {isLoading ? (
        <p className="text-center">Loading...</p>
      ) : (
        <div className="rounded-md shadow-md">
          <div className="hidden lg:grid lg:grid-cols-8 bg-gray-100 py-2 px-4 font-semibold text-sm border-b border-gray-300">
            <div>S.No</div>
            <div>Date</div>
            <div>Pharmacy Name</div>
            <div>Product Name</div>
            <div>MRP</div>
            <div>Discounted Price</div>
            <div>Discount Value</div>
            <div>Action</div>
          </div>
          {couponsData.map((coupon, index) => (
            <div
              key={index}
              className="grid grid-cols-1 lg:grid-cols-8 gap-4 items-center py-4 px-4 border-b border-gray-200 text-sm"
            >
              <div>{index + 1}</div>
              <div>{new Date(coupon.created_at).toLocaleDateString()}</div>
              <div>{coupon.pharmacy_name}</div>
              <div>{coupon.product_name}</div>
              <div>{coupon.mrp}</div>
              <div>{coupon.discounted_price}</div>
              <div>{coupon.discount}</div>
              <div>
                <button
                  onClick={() => handleViewDetails(coupon)}
                  className="bg-gradient-to-r from-[#FB8802] to-[#FFE649] text-white px-4 py-2 rounded-md hover:shadow-md"
                >
                  View
                </button>
              </div>
            </div>
          ))}
        </div>
      )}

      {/* Popup for displaying coupon details */}
      {selectedCoupon && (
        <div className="fixed inset-0 flex items-center justify-center bg-gray-500 bg-opacity-50 z-50">
          <div className="bg-white p-6 rounded-lg shadow-md w-11/12 md:w-1/2 lg:w-1/3">
            <h2 className="text-lg font-semibold mb-4">Coupon Details</h2>
            <div className="text-sm">
              <p>
                <strong>User Id:</strong> {selectedCoupon.user_id}
              </p>
              <p>
                <strong>User Name:</strong> {selectedCoupon.user_name}
              </p>
              <p>
                <strong>User Email:</strong> {selectedCoupon.user_email}
              </p>
              <p>
                <strong>User Phone:</strong> {selectedCoupon.user_phone}
              </p>
              <p>
                <strong>Pharmacy Name:</strong> {selectedCoupon.pharmacy_name}
              </p>
              <p>
                <strong>Product Name:</strong> {selectedCoupon.product_name}
              </p>
              <p>
                <strong>Vendor Id:</strong> {selectedCoupon.vendor_id}
              </p>
              <p>
                <strong>Vendor Phone:</strong> {selectedCoupon.vendor_phone}
              </p>
              <p>
                <strong>Discounted Price:</strong>{" "}
                {selectedCoupon.discounted_price}
              </p>
              <p>
                <strong>Discount:</strong> {selectedCoupon.discount}
              </p>
              <p>
                <strong>MRP:</strong> {selectedCoupon.mrp}
              </p>
              <p>
                <strong>Created At:</strong>{" "}
                {new Date(selectedCoupon.created_at).toLocaleString()}
              </p>
              <p>
                <strong>Expiry:</strong>{" "}
                {new Date(selectedCoupon.expiry).toLocaleString()}
              </p>
              <p>
                <strong>Is Used:</strong> {selectedCoupon.is_used.toString()}
              </p>
              <p>
                <strong>Quantity:</strong> {selectedCoupon.quantity}
              </p>
            </div>
            <button
              onClick={handleClosePopup}
              className="mt-4 bg-gray-400 hover:bg-gray-500 text-white px-4 py-2 rounded-md"
            >
              Close
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default Coupons;
