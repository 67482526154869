import React from 'react';
import { useNavigate } from 'react-router-dom';
const LoginPopup = () => {
  const navigate = useNavigate();
  const handleLoginRedirect = () => {
    navigate('/'); // Redirect to login page
  };

  return (
    <div className="fixed inset-0 flex items-center justify-center z-50">
      <div className="absolute inset-0 bg-gray-800 bg-opacity-75"></div>
      <div className="bg-white p-8 rounded shadow-md text-center z-10">
        <h2 className="text-xl font-semibold mb-4">Session Expired</h2>
        <p className="mb-4">Your session has expired. Please log in again.</p>
        <button
          className="bg-blue-500 text-white px-4 py-2 rounded"
          onClick={handleLoginRedirect}
        >
          Login Again
        </button>
      </div>
    </div>
  );
};

export default LoginPopup;
