import React, { useEffect, useState } from 'react';
import axiosClient from '../components/AxiosClient'; // Import Axios directly

const DiffQueries = () => {
  const [data, setData] = useState(null);
  const [selectedDomain, setSelectedDomain] = useState('Cancer'); // State to track the selected query type
  const [loading, setLoading] = useState(false); // State to manage loading state
  const [error, setError] = useState(null); // State to manage error state
  const [showPopup, setShowPopup] = useState(false); // State to manage popup visibility
  const [actionTakenInput, setActionTakenInput] = useState(''); // State for popup input
  const [selectedQueryId, setSelectedQueryId] = useState(null); // State to track selected query ID

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      setError(null);

      try {
        const token = localStorage.getItem('adminToken'); // Get the token from local storage
        if (!token) {
          console.error('Token not found in localStorage');
          setLoading(false);
          return;
        }

        const config = {
          headers: {
            Authorization: `Bearer ${token}`, // Send the token in the headers
          },
        };

        const formData = new FormData();
        formData.append('domains', selectedDomain); // Append the selected domain

        const response = await axiosClient.post('/query/get_all_queries', formData, config);

        if (response) {
          setData(response.data.queries);
        } else {
          setError(response.data.message || 'Failed to fetch data');
        }
      } catch (error) {
        console.error('Error fetching data:', error);
        setError('No data found');
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [selectedDomain]); // Re-fetch data when selectedDomain changes

  const handleDeleteQuery = async (queryId) => {
    try {
      const token = localStorage.getItem('adminToken'); // Get the token from local storage
      if (!token) {
        console.error('Token not found in localStorage');
        return;
      }

      const config = {
        headers: {
          Authorization: `Bearer ${token}`, // Send the token in the headers
        },
      };

      const response = await axiosClient.post(`/query/delete_query/${queryId}`, null, config);
      console.log('Delete API response:', response.data);

      // Update data after deletion (assuming successful deletion)
      if (response) {
        const updatedQueries = data.filter((query) => query.query_id !== queryId);
        setData(updatedQueries);
      } else {
        console.error('Error deleting query:', response.data.message);
      }
    } catch (error) {
      console.error('Error deleting query:', error);
    }
  };

  const handleActionTaken = async () => {
    try {
      const token = localStorage.getItem('adminToken'); // Get the token from local storage
      if (!token) {
        console.error('Token not found in localStorage');
        return;
      }

      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };

      const formData = new FormData()
      formData.append(
        "text" , actionTakenInput,
      )

      const response = await axiosClient.post(
        `/query/query_action/${selectedQueryId}`,
        formData,
        config
      );

      console.log('Action Taken API response:', response.data);

      // Update action_taken for the specific query in state
      if (response) {
        const updatedData = data.map((query) =>
          query.query_id === selectedQueryId
            ? { ...query, action_taken: actionTakenInput }
            : query
        );
        setData(updatedData);
      }

      setShowPopup(false); // Close popup after submission
    } catch (error) {
      console.error('Error updating action taken:', error);
    }
  };

  const handleStatusChange = async (queryId, newStatus) => {
    try {
      const token = localStorage.getItem('adminToken'); // Get the token from local storage
      if (!token) {
        console.error('Token not found in localStorage');
        return;
      }

      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };

      const formData = {
        status: newStatus,
      };

      const response = await axiosClient.post(
        `/query/update_status/${queryId}`,
        formData,
        config
      );

      console.log('Status Update API response:', response.data);

      // Update the status in the local state
      if (response) {
        const updatedData = data.map((query) =>
          query.query_id === queryId ? { ...query, status: newStatus } : query
        );
        setData(updatedData);
      }
    } catch (error) {
      console.error('Error updating query status:', error);
    }
  };

  return (
    <div className="p-5">
      <h1 className="text-3xl mb-5">Queries</h1>
      <div className="mb-5">
        <label htmlFor="queryType" className="mr-3">
          Select Query Type:
        </label>
        <select
          id="queryType"
          value={selectedDomain}
          onChange={(e) => setSelectedDomain(e.target.value)}
          className="border rounded px-3 py-2"
        >
          <option value="Cancer">Cancer</option>
          <option value="Chronic Care">Chronic Care</option>
          <option value="Generics">Generics</option>
          <option value="Special Needs">Special Needs</option>
        </select>
      </div>
      {loading ? (
        <p>Loading...</p>
      ) : error ? (
        <p className="text-red-500">{error}</p>
      ) : data && data.length > 0 ? (
        <table className="min-w-full bg-white border">
          <thead>
            <tr>
              <th className="py-2 px-4 border-b text-center">Query ID</th>
              <th className="py-2 px-4 border-b text-center">Name</th>
              <th className="py-2 px-4 border-b text-center">Mobile</th>
              <th className="py-2 px-4 border-b text-center">Medicine Type</th>
              <th className="py-2 px-4 border-b text-center">Quantity</th>
              <th className="py-2 px-4 border-b text-center">Domain</th>
              <th className="py-2 px-4 border-b text-center">Date</th>
              <th className="py-2 px-4 border-b text-center">Action Taken</th>
              <th className="py-2 px-4 border-b text-center">Query Status</th>
              <th className="py-2 px-4 border-b text-center">Actions</th>
            </tr>
          </thead>
          <tbody>
            {data.map((query, index) => (
              <tr key={index} className="hover:bg-gray-100">
                <td className="py-2 px-4 border-b text-center">{query.query_id}</td>
                <td className="py-2 px-4 border-b text-center">{query.name}</td>
                <td className="py-2 px-4 border-b text-center">{query.mobile}</td>
                <td className="py-2 px-4 border-b text-center">{query.medicine_type}</td>
                <td className="py-2 px-4 border-b text-center">{query.quantity}</td>
                <td className="py-2 px-4 border-b text-center">{query.domain}</td>
                <td className="py-2 px-4 border-b text-center">{query.date ? new Date(query.date).toLocaleDateString('en-GB') : 'N/A'}
                </td>
                <td className="py-2 px-4 border-b text-center">
                  {query.action_taken || (
                    <button
                      className="bg-blue-500 text-white py-1 px-4 rounded"
                      onClick={() => {
                        setSelectedQueryId(query.query_id);
                        setShowPopup(true);
                      }}
                    >
                      Add Action
                    </button>
                  )}
                </td>
                <td className="py-2 px-4 border-b text-center">
                  <select
                    value={query.status || 'Open'}
                    onChange={(e) => handleStatusChange(query.query_id, e.target.value)}
                    className="border rounded px-2 py-1"
                  >
                    <option value="Open">Open</option>
                    <option value="Close">Close</option>
                  </select>
                </td>
                <td className="py-2 px-4 border-b text-center">
                  <button
                    className="bg-red-500 text-white py-1 px-4 rounded"
                    onClick={() => handleDeleteQuery(query.query_id)}
                  >
                    Delete
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      ) : (
        <p>No queries found.</p>
      )}

      {showPopup && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
          <div className="bg-white p-5 rounded shadow-lg w-1/3">
            <h2 className="text-xl mb-4">Add Action Taken</h2>
            <input
              type="text"
              value={actionTakenInput}
              onChange={(e) => setActionTakenInput(e.target.value)}
              placeholder="Enter action taken"
              className="border w-full p-2 mb-4"
            />
            <button
              className="bg-green-500 text-white px-4 py-2 rounded mr-2"
              onClick={handleActionTaken}
            >
              Submit
            </button>
            <button
              className="bg-gray-500 text-white px-4 py-2 rounded"
              onClick={() => setShowPopup(false)}
            >
              Cancel
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default DiffQueries;
