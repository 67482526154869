import React, { useEffect, useState } from 'react';
import axiosClient from '../components/AxiosClient';

const AlliancesPage = () => {
  const [alliances, setAlliances] = useState([]);
  const [sortOrder, setSortOrder] = useState('asc');
  const [currentPage, setCurrentPage] = useState(1); // Track the current page
  const [totalPages, setTotalPages] = useState(1); // Track total pages for pagination
  const [deletingAllianceId, setDeletingAllianceId] = useState(null);
  const [selectedAlliance, setSelectedAlliance] = useState(null);
  const [showPopup, setShowPopup] = useState(false);
  const [showDetailsPopup, setShowDetailsPopup] = useState(false);
  const [actionAllianceId, setActionAllianceId] = useState(null); // Track which alliance is being acted upon
  const [actionText, setActionText] = useState(''); // Track the entered action
  const [showActionPopup, setShowActionPopup] = useState(false); // Show/hide action popup modal

  const [actionTakenInput, setActionTakenInput] = useState('');

  const adminToken = localStorage.getItem('adminToken');

  // Fetch alliances from the API
  const fetchAlliances = async () => {
    try {
      const response = await axiosClient.post(`/alliances/all/${sortOrder}/${currentPage}/${10}`, {});
      setAlliances(response.data.data[0].alliances);
      setTotalPages(response.data.data[0].total_pages); // Assume total_pages is returned in the response
    } catch (error) {
      console.error('Error fetching alliances', error);
    }
  };

  // Fetch alliances whenever sortOrder or currentPage changes
  useEffect(() => {
    fetchAlliances();
  }, [sortOrder, currentPage]);

  // Handle sorting
  const handleSort = () => {
    setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
  };

  // Handle deleting an alliance
  const handleDelete = async (id) => {
    try {
      await axiosClient.post(`/alliances/delete_alliance/${id}`);
      setAlliances(alliances.filter((a) => a._id !== id)); // Remove the deleted alliance
      setShowPopup(false);
    } catch (error) {
      console.error('Error deleting alliance', error);
    }
  };

  // Confirm delete popup
  const confirmDelete = (id) => {
    setDeletingAllianceId(id);
    setShowPopup(true);
  };

  // Handle viewing alliance details
  const handleViewDetails = async (id) => {
    try {
      const response = await axiosClient.post(`/alliances/search/${id}`);
      setSelectedAlliance(response.data.data[0]); // Store the alliance details
      setShowDetailsPopup(true); // Open the details popup
    } catch (error) {
      console.error('Error fetching alliance details', error);
    }
  };

  // Handle action popup
  const handleActionPopup = (id) => {
    setActionAllianceId(id);
    setShowActionPopup(true);
  };

   // Handle the action input change
   const handleActionInputChange = (e) => {
    setActionTakenInput(e.target.value);
  };

   // Handle save action
   const handleActionTaken = async () => {
    console.log(`Action for alliance ID ${actionAllianceId}: ${actionTakenInput}`);
    ; // Clear the input box
    try {
      const formdata=new FormData()
      formdata.append("text", actionTakenInput)
      await axiosClient.post(`/alliances/alliance_action/${actionAllianceId}`, formdata, {
        headers: {
          Authorization: `Bearer ${adminToken}`,
        },
      });
      alert('Action added successfully!');
      // onClose();
    } catch (error) {
      console.error('Error adding action:', error);
      alert('Failed to add action.');
    }
    setActionTakenInput('')
    setShowActionPopup(false);
    setSortOrder("asc")
  };

  // Save action
  const saveAction = () => {
    console.log(`Action for alliance ID ${actionAllianceId}: ${actionText}`);
    setShowActionPopup(false);
    setActionText(''); // Clear the input box
  };

  // Handle pagination
  const goToPreviousPage = () => {
    if (currentPage > 1) setCurrentPage(currentPage - 1);
  };

  const goToNextPage = () => {
    if (currentPage < totalPages) setCurrentPage(currentPage + 1);
  };

  return (
    <div>
      <h1>Alliances</h1>
      <button className="sort-button" onClick={handleSort}>
        Sort {sortOrder === 'asc' ? 'Descending' : 'Ascending'}
      </button>

      {/* Table Format */}
      <table className="alliances-table">
        <thead>
          <tr>
            <th>Name</th>
            <th>Company</th>
            <th>Details</th>
            <th>Delete</th>
            <th>Action Taken</th>
            <th>Query</th>
          </tr>
        </thead>
        <tbody>
          {alliances.map((alliance) => (
            <tr key={alliance._id}>
              <td>{alliance.name}</td>
              <td>{alliance.company_name}</td>
              <td>
                <button
                  className="details-button"
                  onClick={() => handleViewDetails(alliance._id)}
                >
                  Details
                </button>
              </td>
              <td>
                <button
                  className="delete-button"
                  onClick={() => confirmDelete(alliance._id)}
                >
                  Delete
                </button>
              </td>
              <td>
                <button
                  className="action-button"
                  onClick={() => handleActionPopup(alliance._id)}
                >
                  Add Action
                </button>
              </td>
              <td>{Array.isArray(alliance.action) ? alliance.action.join(', ') : 'No queries'}</td>
            </tr>
          ))}
        </tbody>
      </table>

      {/* Pagination Controls */}
      <div className="pagination">
        <button
          className="page-button"
          onClick={goToPreviousPage}
          disabled={currentPage === 1}
        >
          Previous
        </button>
        <span>
          Page {currentPage} of {totalPages}
        </span>
        <button
          className="page-button"
          onClick={goToNextPage}
          disabled={currentPage === totalPages}
        >
          Next
        </button>
      </div>

      {showPopup && (
        <div className="popup">
          <p>Are you sure you want to delete this alliance?</p>
          <div className="popup-buttons">
            <button
              className="confirm-button"
              onClick={() => handleDelete(deletingAllianceId)}
            >
              Yes
            </button>
            <button
              className="cancel-button"
              onClick={() => setShowPopup(false)}
            >
              No
            </button>
          </div>
        </div>
      )}

      {showDetailsPopup && selectedAlliance && (
        <div className="popup">
          <h3>Alliance Details</h3>
          <p>
            <strong>Name:</strong> {selectedAlliance.name}
          </p>
          <p>
            <strong>Company:</strong> {selectedAlliance.company_name}
          </p>
          <p>
            <strong>Email:</strong> {selectedAlliance.email}
          </p>
          <p>
            <strong>Mobile:</strong> {selectedAlliance.mobile}
          </p>
          <p>
            <strong>Address:</strong> {selectedAlliance.buisness_address}
          </p>
          <p>
            <strong>Message:</strong> {selectedAlliance.message}
          </p>
          <p>
            <strong>How did you hear about us:</strong>{' '}
            {selectedAlliance.how_did_you_hear_about_us}
          </p>
          <button
            className="close-button"
            onClick={() => setShowDetailsPopup(false)}
          >
            Close
          </button>
        </div>
      )}

{showActionPopup && (
  <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
    <div className="bg-white p-5 rounded shadow-lg w-1/3">
      <h2 className="text-xl mb-4">Add Action Taken</h2>
      <input
        type="text"
        value={actionTakenInput}
        onChange={(e) => setActionTakenInput(e.target.value)}
        placeholder="Enter action taken"
        className="border w-full p-2 mb-4"
      />
      <button
        className="bg-green-500 text-white px-4 py-2 rounded mr-2"
        onClick={handleActionTaken}
      >
        Submit
      </button>
      <button
        className="bg-gray-500 text-white px-4 py-2 rounded"
        onClick={() => setShowActionPopup(false)}
      >
        Cancel
      </button>
    </div>
  </div>
)}

      <style jsx>{`
        .alliances-table {
          width: 100%;
          border-collapse: collapse;
          margin-top: 20px;
        }

        .alliances-table th,
        .alliances-table td {
          border: 1px solid #ddd;
          padding: 8px;
          text-align: center;
        }

        .alliances-table th {
          background-color: #f4f4f4;
          font-weight: bold;
        }

        .sort-button,
        .details-button,
        .delete-button,
        .action-button,
        .page-button,
        .confirm-button,
        .cancel-button {
          padding: 10px 15px;
          border: none;
          border-radius: 5px;
          cursor: pointer;
          font-size: 14px;
          transition: background-color 0.3s ease, transform 0.2s ease;
        }

        .sort-button {
          background-color: #007bff;
          color: white;
        }

        .details-button {
          background-color: #17a2b8;
          color: white;
        }

        .delete-button {
          background-color: #dc3545;
          color: white;
        }

        .action-button {
          background-color: #ffc107;
          color: black;
        }

        .popup {
          position: fixed;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          background-color: white;
          padding: 20px;
          box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
          border-radius: 10px;
          z-index: 1000;
        }

        .popup-buttons {
          display: flex;
          justify-content: space-between;
        }
      `}</style>
    </div>
  );
};

export default AlliancesPage;
