import React, { useState, useEffect } from 'react';
import axiosClient from '../components/AxiosClient';

const Users = () => {
  const [userData, setUserData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [sortField, setSortField] = useState(null);
  const [sortOrder, setSortOrder] = useState('asc'); // 'asc' or 'desc'
  const [showModal, setShowModal] = useState(false);
  const [selectedUserId, setSelectedUserId] = useState(null);
  const [actionMessage, setActionMessage] = useState('');
  const adminToken = localStorage.getItem('adminToken');

  useEffect(() => {
    const fetchData = async () => {
      try {
        const adminToken = localStorage.getItem('adminToken');
        if (!adminToken) {
          console.error('Admin token not found.');
          return;
        }

        const config = {
          headers: {
            Authorization: `Bearer ${adminToken}`,
          },
        };

        const response = await axiosClient.post(
          '/users/get_users_details/1/10',
          null,
          config
        );
        setUserData(response.data);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching user data:', error);
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const generateUniqueId = (name, index) => {
    return `${name}-${index}`;
  };

  const sortUsers = () => {
    if (!userData || !userData.data || !sortField) return;

    const sortedData = [...userData.data].map((userGroup) =>
      [...userGroup].sort((a, b) => {
        if (sortField === 'name') {
          return sortOrder === 'asc'
            ? a.name.localeCompare(b.name)
            : b.name.localeCompare(a.name);
        }
        if (sortField === 'date') {
          return sortOrder === 'asc'
            ? new Date(a.date) - new Date(b.date)
            : new Date(b.date) - new Date(a.date);
        }
        return 0;
      })
    );

    setUserData((prev) => ({
      ...prev,
      data: sortedData,
    }));
  };

  useEffect(sortUsers, [sortField, sortOrder]); // Re-sort whenever field or order changes

  const handleActionButtonClick = (userId) => {
    setSelectedUserId(userId);
    setShowModal(true);
  };

  const handleModalSubmit = async () => {
    // Submit the action for the user
    console.log(`Action submitted for user: ${selectedUserId}, message: ${actionMessage}`);
    setShowModal(false);
    
    try {
      const formdata=new FormData()
      formdata.append("text", actionMessage)
      await axiosClient.post(`/users/user_action/${selectedUserId}`, formdata, {
        headers: {
          Authorization: `Bearer ${adminToken}`,
        },
      });
      alert('Action added successfully!');
      // onClose();
    } catch (error) {
      console.error('Error adding action:', error);
      alert('Failed to add action.');
    }
    setActionMessage('');
    setSortField(1)
    
  };

  const handleModalCancel = () => {
    setShowModal(false);
    setActionMessage('');
  };

  const handleMessageChange = (e) => {
    setActionMessage(e.target.value);
  };

  return (
    <div className="p-4">
      <h1 className="text-2xl font-bold mb-4 text-center">Users</h1>

      {/* Sorting Block */}
      <div className="flex items-center justify-center mb-4 space-x-4 bg-gray-100 p-3 rounded-md shadow">
        <label htmlFor="sortField" className="font-medium text-gray-700">
          Sort By:
        </label>
        <select
          id="sortField"
          className="border border-gray-300 rounded-md p-2 text-gray-700 focus:outline-none focus:ring-2 focus:ring-orange-400"
          onChange={(e) => setSortField(e.target.value)}
        >
          <option value="">-- Select --</option>
          <option value="name">Name</option>
          <option value="date">Date</option>
        </select>
        <button
          onClick={() => setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc')}
          className="bg-[#FB8802] hover:bg-[#FFE649] text-white px-4 py-2 rounded-md focus:outline-none focus:ring-2 focus:ring-orange-400"
        >
          <span>{sortOrder === 'asc' ? '↑' : '↓'}</span>
        </button>
      </div>

      {loading ? (
        <p className="text-center">Loading...</p>
      ) : userData ? (
        <div className="overflow-x-auto">
          <table className="w-full table-auto border-collapse border border-gray-300">
            <thead>
              <tr className="bg-gray-200 text-sm sm:text-base">
                <th className="text-left px-2 py-2 border">SNo</th>
                <th className="text-left px-2 py-2 border">Name</th>
                <th className="text-left px-2 py-2 border">Mobile</th>
                <th className="text-left px-2 py-2 border">Email</th>
                <th className="text-left px-2 py-2 border">Unique ID</th>
                <th className="text-left px-2 py-2 border">Date</th>
                <th className="text-left px-2 py-2 border">Action Taken</th>
                <th className="text-left px-2 py-2 border">Coupons Downloaded</th>
                <th className="text-left px-2 py-2 border">Queries</th>
                <th className="text-left px-2 py-2 border">Delete</th>
              </tr>
            </thead>
            <tbody>
              {userData.data.map((userGroup, index) => (
                <React.Fragment key={index}>
                  {userGroup.map((user) => (
                    <tr
                      key={user.id}
                      className="text-sm sm:text-base hover:bg-gray-100"
                    >
                      <td className="px-2 py-2 border">{index * 10 + userGroup.indexOf(user) + 1}</td>
                      <td className="px-2 py-2 border">{user.name}</td>
                      <td className="px-2 py-2 border">{user.mobile}</td>
                      <td className="px-2 py-2 border">{user.email}</td>
                      <td className="px-2 py-2 border">
                        {generateUniqueId(user.name, index * 10 + userGroup.indexOf(user))}
                      </td>
                      <td className="px-2 py-2 border">
                      {user.account_created_on ? 
    (new Date(user.account_created_on).toString() !== 'Invalid Date' 
      ? new Date(user.account_created_on).toLocaleDateString() 
      : 'N/A') 
    : 'N/A'}
                      </td>
                      <td className="px-2 py-2 border">
                        <button
                          onClick={() => handleActionButtonClick(user.id)}
                          className="bg-blue-500 text-white px-4 py-2 rounded-md"
                        >
                          Action
                        </button>
                      </td>
                      <td className="px-2 py-2 border">{user.coupons || 0}</td>
                      <td className="px-2 py-2 border"> {Array.isArray(user.action) ? user.action.join(', ') : 'No queries'}</td>
                      <td className="px-2 py-2 border text-red-500 cursor-pointer hover:underline">
                        Delete
                      </td>
                    </tr>
                  ))}
                </React.Fragment>
              ))}
            </tbody>
          </table>
        </div>
      ) : (
        <p className="text-center">No users found.</p>
      )}

      {/* Action Modal */}
      {showModal && (
        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 flex items-center justify-center z-50">
          <div className="bg-white p-6 rounded-md shadow-lg w-96">
            <h2 className="text-xl font-bold mb-4">Action Taken</h2>
            <textarea
              value={actionMessage}
              onChange={handleMessageChange}
              rows="4"
              className="w-full border p-2 rounded-md"
              placeholder="Enter action message..."
            />
            <div className="mt-4 flex justify-end space-x-4">
              <button
                onClick={handleModalCancel}
                className="bg-gray-300 text-gray-700 px-4 py-2 rounded-md"
              >
                Cancel
              </button>
              <button
                onClick={handleModalSubmit}
                className="bg-blue-500 text-white px-4 py-2 rounded-md"
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Users;
