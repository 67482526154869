import React from 'react';
import axiosClient from '../components/AxiosClient';

const DeleteTestimonial = ({ testimonialId, onClose, onDelete }) => {
  const handleDelete = async () => {
    try {
      const token = localStorage.getItem('adminToken');
      if (!token) {
        console.error('Token not found in localStorage');
        return;
      }

      const config = {
        headers: {
          'Authorization': `Bearer ${token}`,
        },
      };

      const formData = new FormData();
      formData.append('id', testimonialId);

      const response = await axiosClient.post('/testimonial/delete_testimonial', formData, config);
      console.log('Delete API response:', response.data);

      if (response) {
        onDelete(testimonialId);
        onClose();
      } else {
        console.error('Error deleting testimonial:', response.data.message);
      }
    } catch (error) {
      console.error('Error deleting testimonial:', error);
    }
  };

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-50">
      <div className="bg-white p-8 rounded-md shadow-md w-1/3">
        <h2 className="text-lg font-semibold mb-4">Delete Testimonial</h2>
        <p>Are you sure you want to delete this testimonial?</p>
        <div className="flex justify-end mt-4">
          <button type="button" className="bg-gray-500 text-white px-4 py-2 rounded mr-2" onClick={onClose}>
            Cancel
          </button>
          <button type="button" className="bg-red-500 text-white px-4 py-2 rounded" onClick={handleDelete}>
            Delete
          </button>
        </div>
      </div>
    </div>
  );
};

export default DeleteTestimonial;
