import React, { useState } from "react";
import axiosClient from '../components/AxiosClient';

const Createsmartdeal = () => {
  const [productId, setProductId] = useState("");
  const [discountPercentage, setDiscountPercentage] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [message, setMessage] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      // Retrieve vendor token from local storage
      const adminToken = localStorage.getItem("adminToken");

      // Check if vendor token exists
      if (adminToken) {
        // Create a FormData object and append form values
        const formData = new FormData();
        formData.append('product_id', productId);
        formData.append('discount_percentage', discountPercentage);
        formData.append('start_date', new Date(startDate).toISOString());
        formData.append('end_date', new Date(endDate).toISOString());

        const response = await axiosClient.post(
          "smartdeals/create_deal_admin",
          formData,
          {
            headers: {
              Authorization: `Bearer ${adminToken}`,
              'Content-Type': 'multipart/form-data'
            },
          }
        );
        setMessage("Deal created successfully!");
      } else {
        console.error("Vendor token not found in local storage");
        setMessage("Vendor token not found. Please log in.");
      }
    } catch (error) {
      console.error("Error creating deal:", error);
      setMessage("Error creating deal: " + error.message);
    }
  };

  return (
    <div className="max-w-4xl mx-auto mt-8 p-6 bg-white rounded-lg shadow-lg">
      <h1 className="text-3xl font-semibold text-center mb-6">Create Smart Deal</h1>
      <form onSubmit={handleSubmit} className="space-y-4">
        <div>
          <label htmlFor="productId" className="block text-sm font-medium text-gray-700">Product ID:</label>
          <input
            type="text"
            id="productId"
            value={productId}
            onChange={(e) => setProductId(e.target.value)}
            required
            className="mt-1 p-2 w-full border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
          />
        </div>

        <div>
          <label htmlFor="discountPercentage" className="block text-sm font-medium text-gray-700">Discount Percentage:</label>
          <input
            type="number"
            id="discountPercentage"
            value={discountPercentage}
            onChange={(e) => setDiscountPercentage(e.target.value)}
            required
            className="mt-1 p-2 w-full border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
          />
        </div>

        <div className="flex space-x-4">
          <div className="w-1/2">
            <label htmlFor="startDate" className="block text-sm font-medium text-gray-700">Start Date:</label>
            <input
              type="date"
              id="startDate"
              value={startDate}
              onChange={(e) => setStartDate(e.target.value)}
              required
              className="mt-1 p-2 w-full border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
          </div>

          <div className="w-1/2">
            <label htmlFor="endDate" className="block text-sm font-medium text-gray-700">End Date:</label>
            <input
              type="date"
              id="endDate"
              value={endDate}
              onChange={(e) => setEndDate(e.target.value)}
              required
              className="mt-1 p-2 w-full border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
          </div>
        </div>

        <div className="flex justify-center">
          <button
            type="submit"
            className="bg-blue-500 text-white px-6 py-2 rounded-md hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500"
          >
            Create Deal
          </button>
        </div>
      </form>

      {message && (
        <div className="mt-4 text-center">
          <p className={`text-sm ${message.includes("Error") ? 'text-red-500' : 'text-green-500'}`}>{message}</p>
        </div>
      )}
    </div>
  );
};

export default Createsmartdeal;
