import React, { useState, useEffect } from 'react';
import axiosClient from '../components/AxiosClient'; // Assuming Axios client is configured here

const AllVendors = () => {
  const [vendors, setVendors] = useState([]);
  const [sortedVendors, setSortedVendors] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [sortCriteria, setSortCriteria] = useState('');

  useEffect(() => {
    const fetchVendors = async () => {
      setLoading(true);
      setError(null);

      try {
        // Fetch adminToken from localStorage
        const adminToken = localStorage.getItem('adminToken');

        // Axios POST request configuration
        const config = {
          headers: {
            Authorization: `Bearer ${adminToken}`, // Assuming your API expects Bearer token
          },
        };

        const response = await axiosClient.post('/vendors/get_all_vendors', null, config);
        console.log('Vendors API response:', response.data);
        setVendors(response.data.vendors);
        setSortedVendors(response.data.vendors); // Initialize sortedVendors with the fetched data
        setLoading(false);
      } catch (error) {
        console.error('Error fetching vendors:', error);
        setError('Failed to fetch vendors');
        setLoading(false);
      }
    };

    fetchVendors();
  }, []);

  const handleSortChange = (criteria) => {
    setSortCriteria(criteria);
  
    const sorted = [...vendors]; // Clone the original vendors list
    switch (criteria) {
      case 'name':
        sorted.sort((a, b) => a.vendor_name.localeCompare(b.vendor_name));
        break;
  
      case 'date_of_request':
        sorted.sort((a, b) => {
          const dateA = new Date(a.request_date || '1970-01-01'); // Fallback to a default date
          const dateB = new Date(b.request_date || '1970-01-01');
          return dateA - dateB;
        });
        break;
  
      case 'onboarding_status':
        sorted.sort((a, b) => {
          const statusA = a.onboarding_status || ''; // Fallback to an empty string
          const statusB = b.onboarding_status || '';
          return statusA.localeCompare(statusB);
        });
        break;
  
      case 'pending_requests':
        sorted.sort((a, b) => (a.pending_requests || 0) - (b.pending_requests || 0));
        break;
  
      case 'expiry_date':
        sorted.sort((a, b) => {
          const dateA = new Date(a.expiry_date || '1970-01-01'); // Fallback to a default date
          const dateB = new Date(b.expiry_date || '1970-01-01');
          return dateA - dateB;
        });
        break;
  
      default:
        break;
    }
  
    setSortedVendors(sorted);
  };
  

  return (
    <div className="p-5">
      <h1 className="text-3xl mb-5">All Vendors</h1>
      <div className="mb-5">
        <label htmlFor="sort" className="mr-2 text-lg">
          Sort by:
        </label>
        <select
          id="sort"
          value={sortCriteria}
          onChange={(e) => handleSortChange(e.target.value)}
          className="border px-2 py-1 rounded-md"
        >
          <option value="">Select</option>
          <option value="name">Name</option>
          <option value="date_of_request">Date of Request</option>
          <option value="onboarding_status">Onboarding Status</option>
          <option value="pending_requests">Pending Requests</option>
          <option value="expiry_date">Date of Expiry</option>
        </select>
      </div>
      {loading ? (
        <p>Loading vendors...</p>
      ) : error ? (
        <p className="text-red-500">{error}</p>
      ) : sortedVendors.length > 0 ? (
        <div className="overflow-x-auto">
          <table className="min-w-full bg-white border">
            <thead>
              <tr>
                <th className="py-2 px-4 border-b text-center">Vendor ID</th>
                <th className="py-2 px-4 border-b text-center">Vendor Name</th>
                <th className="py-2 px-4 border-b text-center">Mobile</th>
                <th className="py-2 px-4 border-b text-center">Email</th>
                <th className="py-2 px-4 border-b text-center">Pharmacy Address</th>
                <th className="py-2 px-4 border-b text-center">Pharmacy City</th>
                <th className="py-2 px-4 border-b text-center">Pharmacy Count</th>
                <th className="py-2 px-4 border-b text-center">Latitude</th>
                <th className="py-2 px-4 border-b text-center">Longitude</th>
                <th className="py-2 px-4 border-b text-center">Active</th>
                <th className="py-2 px-4 border-b text-center">Payment Status</th>
                <th className="py-2 px-4 border-b text-center">Payment Date</th>
                <th className="py-2 px-4 border-b text-center">Date of Request</th>
                <th className="py-2 px-4 border-b text-center">Onboarding Status</th>
                <th className="py-2 px-4 border-b text-center">Pending Requests</th>
                <th className="py-2 px-4 border-b text-center">Date of Expiry</th>
              </tr>
            </thead>
            <tbody>
              {sortedVendors.map((vendor) => (
                <tr key={vendor.vendor_id} className="hover:bg-gray-100">
                  <td className="py-2 px-4 border-b text-center">{vendor.vendor_id}</td>
                  <td className="py-2 px-4 border-b text-center">{vendor.vendor_name}</td>
                  <td className="py-2 px-4 border-b text-center">{vendor.phone}</td>
                  <td className="py-2 px-4 border-b text-center">{vendor.email}</td>
                  <td className="py-2 px-4 border-b text-center">{vendor.pharmacy_address}</td>
                  <td className="py-2 px-4 border-b text-center">{vendor.city}</td>
                  <td className="py-2 px-4 border-b text-center">{vendor.pharmacy_count}</td>
                  <td className="py-2 px-4 border-b text-center">{vendor.latitude}</td>
                  <td className="py-2 px-4 border-b text-center">{vendor.longitude}</td>
                  <td className="py-2 px-4 border-b text-center">{vendor.active ? 'Active' : 'Inactive'}</td>
                  <td className="py-2 px-4 border-b text-center">{vendor.payment_status}</td>
                  <td className="py-2 px-4 border-b text-center">{vendor.payment_date}</td>
                  <td className="py-2 px-4 border-b text-center">{vendor.request_date || 'N/A'}</td>
                  <td className="py-2 px-4 border-b text-center">{vendor.onboarding_status || 'N/A'}</td>
                  <td className="py-2 px-4 border-b text-center">{vendor.pending_requests || 0}</td>
                  <td className="py-2 px-4 border-b text-center">{vendor.expiry_date || 'N/A'}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      ) : (
        <p>No vendors found.</p>
      )}
    </div>
  );
};

export default AllVendors;
