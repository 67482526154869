import React, { useState} from 'react';
import adminContext from './adminContext';
import axiosClient from '../../components/AxiosClient';

const AdminState = (props) => {
  const [expiredToken, setExpiredToken] = useState(false);

  const checkTokenExpiry = async () => {
    const adminToken = localStorage.getItem('adminToken');
    if (!adminToken) {
      setExpiredToken(true);
      return;
    }

    try {
      const response = await axiosClient.post('/auth/token_expiry_check', null, {
        headers: {
          Authorization: `Bearer ${adminToken}`,
        },
      });

      if (response.data.code !== 200) {
        setExpiredToken(true);
      } else {
        setExpiredToken(false);
      }
    } catch (error) {
      setExpiredToken(true);
    }
  };

  return (
    <adminContext.Provider value={{ expiredToken, checkTokenExpiry }}>
      {props.children}
    </adminContext.Provider>
  );
};

export default AdminState;
