import React, { useState } from 'react';
import axios from 'axios';
import axiosClient from './AxiosClient';

const EditPopup = ({ faq, onClose, onSave }) => {
  const [question, setQuestion] = useState(faq.question);
  const [answer, setAnswer] = useState(faq.answer);

  const handleSave = async () => {
    try {
      const token = localStorage.getItem('adminToken'); // Get admin token from local storage
      const config = {
        headers: {
          'Content-Type': 'multipart/form-data',
          'Authorization': `Bearer ${token}`, // Add token to headers
        },
      };

      const formData = new FormData();
      formData.append('id', faq._id);
      formData.append('question', question);
      formData.append('answer', answer);

      const response = await axiosClient.post('/faq/edit_faq', formData, config);
      console.log('Edit response:', response.data);

      if (response.data.code === 200) {
        onSave({ ...faq, question, answer });
      } else {
        console.error('Error updating FAQ:', response.data.message);
      }
    } catch (error) {
      console.error('Error saving FAQ:', error);
    }

    onClose();
  };

  return (
    <div className="fixed inset-0 bg-gray-600 bg-opacity-50 flex items-center justify-center">
      <div className="bg-white p-5 rounded-lg shadow-lg w-1/2">
        <h2 className="text-2xl mb-4">Edit FAQ</h2>
        <div className="mb-4">
          <label className="block text-gray-700">Question</label>
          <input
            type="text"
            value={question}
            onChange={(e) => setQuestion(e.target.value)}
            className="w-full p-2 border rounded"
          />
        </div>
        <div className="mb-4">
          <label className="block text-gray-700">Answer</label>
          <textarea
            value={answer}
            onChange={(e) => setAnswer(e.target.value)}
            className="w-full p-2 border rounded"
          />
        </div>
        <div className="flex justify-end">
          <button
            onClick={onClose}
            className="bg-red-500 text-white px-4 py-2 rounded mr-2"
          >
            Cancel
          </button>
          <button
            onClick={handleSave}
            className="bg-blue-500 text-white px-4 py-2 rounded"
          >
            Save
          </button>
        </div>
      </div>
    </div>
  );
};

export default EditPopup;
