import React, { useState, useEffect, useCallback } from "react";
import axiosClient from '../components/AxiosClient';
import { useNavigate } from 'react-router-dom'; // Import useNavigate for redirecting

const Allsmartdeals = () => {
  const [selectedValue, setSelectedValue] = useState("");
  const [deals, setDeals] = useState([]);
  const [apptocal, setapptocal]=useState(null)
  const [id, setId] = useState("");
  const [notification, setNotification] = useState(""); // Notification state
  const [loading, setLoading] = useState(null); // Loading state for each deal
  const navigate = useNavigate(); // Initialize useNavigate

  // Handle dropdown selection
  const handleChange = (event) => {
    setSelectedValue(event.target.value);
  };

  const handleApproval = useCallback(async (deal_id, status) => {
    try {
      const adminToken = localStorage.getItem('adminToken');
      if (adminToken) {
        const headers = {
          'Authorization': `Bearer ${adminToken}`,
        };

        let formData = new FormData();
        formData.append('deal_id', deal_id);
        formData.append('status_', status);
        headers['Content-Type'] = 'multipart/form-data';

        const response = await axiosClient.post(`smartdeals/update_deal_status`, formData, { headers });
        console.log(response.status)
        if (response.status===200) {
          // setDeals(response.data.deals);
          setapptocal(true)
          console.log("dd")
        } else {
          console.error('Invalid response structure:', response.data);
        }
      } else {
        console.error('Admin token not found in local storage');
      }
    } catch (error) {
      console.error('Error updating deal status:', error);
    }
  }, []);

  const handleId = (event) => {
    setId(event.target.value);
  };

  // Check expiry status
  const checkExpiry = (endDate) => {
    if (!endDate) return "Not Expired";
    const today = new Date();
    const end = new Date(endDate);
    return end < today ? "Expired" : "Not Expired";
  };

  // Fetch deals from API
  // const fetchDeals = async () => {
  //   try {
  //     const adminToken = localStorage.getItem('adminToken');
  //     if (!adminToken) {
  //       console.error('Admin token not found in local storage');
  //       return;
  //     }

  //     const headers = {
  //       'Authorization': `Bearer ${adminToken}`,
  //     };

  //     let formData = null;

  //     if (selectedValue || id) {
  //       formData = new FormData();
  //       if (selectedValue && selectedValue !== "expired" && selectedValue !== "not_expired") {
  //         formData.append('status_', selectedValue);
  //       }
  //       if (id) {
  //         formData.append('vendor_id', id);
  //       }
  //       headers['Content-Type'] = 'multipart/form-data';
  //     }

  //     console.log('Fetching Deals with Filters:', { selectedValue, id });

  //     const response = await axiosClient.post(`smartdeals/get_all_deals`, formData, { headers });

  //     console.log('Fetched Deals Response:', response);

  //     if (response.data && response.data.deals) {
  //       setDeals(response.data.deals);
  //     } else {
  //       console.error('Invalid response structure:', response.data);
  //     }
  //   } catch (error) {
  //     console.error('Error fetching deals:', error);
  //   }
  // };

  // Handle approval/rejection
  // const handleApproval = async (deal_id, status) => {
  //   try {
  //     setLoading(deal_id); // Set loading state for the current deal
  //     const adminToken = localStorage.getItem('adminToken');
  //     if (!adminToken) {
  //       console.error('Admin token not found in local storage');
  //       return;
  //     }

  //     const headers = {
  //       'Authorization': `Bearer ${adminToken}`,
  //       'Content-Type': 'multipart/form-data',
  //     };

  //     let formData = new FormData();
  //     formData.append('deal_id', deal_id);
  //     formData.append('status_', status);

  //     console.log('Sending Approval/Reject Request:', deal_id, status);

  //     const response = await axiosClient.post(`smartdeals/update_deal_status`, formData, { headers });

  //     console.log('API Response:', response);

  //     if (response.data && response.data.success) {
  //       // Update the deals state to immediately remove the deal
  //       setDeals((prevDeals) =>
  //         prevDeals.filter((deal) => deal.deal_id !== deal_id)
  //       );

  //       // Set notification message
  //       setNotification(`Deal ${status === 'approved' ? 'approved' : 'rejected'} successfully!`);

  //       // Redirect based on the action taken
  //       navigate(`/deals/${status}`); // Redirect to approved or rejected page

  //       // Clear notification after 3 seconds
  //       setTimeout(() => setNotification(""), 3000);
  //     } else {
  //       console.error('Invalid response structure:', response.data);
  //     }
  //   } catch (error) {
  //     console.error('Error updating deal status:', error);
  //   } finally {
  //     setLoading(null); // Reset loading state once the operation completes
  //   }
  // };

  // Fetch deals on component mount or filter change
  useEffect(() => {
    fetchDeals();
  }, [selectedValue, id]);

  // Filter deals by expiry status
  const filteredDeals = deals.filter((deal) => {
    const expiryStatus = checkExpiry(deal.end_date);
    if (selectedValue === "expired") {
      return expiryStatus === "Expired";
    }
    if (selectedValue === "not_expired") {
      return expiryStatus === "Not Expired";
    }
    return true;
  });

  const fetchDeals = useCallback(async () => {
    try {
      const adminToken = localStorage.getItem('adminToken');
      if (adminToken) {
        const headers = {
          'Authorization': `Bearer ${adminToken}`,
        };

        let formData = null;

        if (selectedValue || id) {
          formData = new FormData();
          if (selectedValue) {
            // Skip adding formData for "expired"/"not_expired" as it's handled on the frontend
            if (selectedValue !== "expired" && selectedValue !== "not_expired") {
              formData.append('status_', selectedValue);
            }
          }
          if (id) {
            formData.append('vendor_id', id);
          }
          headers['Content-Type'] = 'multipart/form-data';
        }

        const response = await axiosClient.post(`smartdeals/get_all_deals`, formData, { headers });

        if (response.data && response.data.deals) {
          setDeals(response.data.deals);
        } else {
          console.error('Invalid response structure:', response.data);
        }
      } else {
        console.error('Admin token not found in local storage');
      }
    } catch (error) {
      console.error('Error fetching deals:', error);
    }
  }, [selectedValue, id]);

  useEffect(() => {
    console.log("kjlkjljk")
    fetchDeals();
  }, [apptocal]);

  return (
    <div className="container mx-auto p-4">
      <h2 className="text-2xl font-semibold mb-6">Smart Deals</h2>

      {/* Notification */}
      {notification && (
        <div className="mb-4 p-4 bg-green-100 text-green-800 rounded-md transition duration-300 ease-in-out">
          {notification}
        </div>
      )}

      {/* Status Filter */}
      <div className="mb-4">
        <label htmlFor="status-dropdown" className="block text-lg mb-2">Select Status:</label>
        <select
          id="status-dropdown"
          value={selectedValue}
          onChange={handleChange}
          className="w-full sm:w-auto p-2 border border-gray-300 rounded-md"
        >
          <option value="">All</option>
          <option value="approved">Approved</option>
          <option value="rejected">Rejected</option>
          <option value="pending">Pending</option>
          <option value="expired">Expired</option>
          <option value="not_expired">Not Expired</option>
        </select>
      </div>

      {/* Vendor ID Input */}
      <div className="mb-6">
        <label htmlFor="vendor-id" className="block text-lg mb-2">Enter Vendor ID (Leave empty if you want to view all smart deals):</label>
        <input
          type="text"
          id="vendor-id"
          placeholder="Enter the vendor ID"
          onChange={handleId}
          className="w-full sm:w-auto p-2 border border-gray-300 rounded-md"
        />
      </div>

      {/* Deals List */}
      <div>
        {filteredDeals.length === 0 ? (
          <p>No deals available.</p>
        ) : (
          filteredDeals.map((deal) => (
            <div
              key={deal.deal_id}
              className="bg-slate-200 p-4 mb-4 rounded-md shadow-md transition-opacity duration-300 ease-in-out"
              style={{ opacity: loading === deal.deal_id ? 0.5 : 1 }}
            >
              <div className="mb-2">Product Id: {deal.product_id}</div>
              <div className="mb-2">Vendor Id: {deal.vendor_id}</div>
              <div className="mb-2">Discount: {deal.discount_percentage}%</div>
              <div className="mb-2">Start Date: {new Date(deal.start_date).toLocaleDateString()}</div>
              <div className="mb-2">End Date: {new Date(deal.end_date).toLocaleDateString()}</div>
              <div className="mb-2">Status: {deal.status}</div>
              <div className="mb-2">Created at: {new Date(deal.created_at).toLocaleDateString()}</div>
              <div className="mb-2">Expiry: {checkExpiry(deal.end_date)}</div>

              {/* Approve/Reject Buttons */}
              {deal.status === "pending" && (
                <div className="flex space-x-4">
                  <button
                    className={`bg-[#FB8802] hover:bg-[#FFE649] text-white px-4 py-2 rounded-md focus:outline-none ${loading === deal.deal_id ? 'opacity-50 cursor-not-allowed' : ''}`}
                    disabled={loading === deal.deal_id}
                    onClick={() => handleApproval(deal.deal_id, "approved")}
                  >
                    {loading === deal.deal_id ? 'Processing...' : 'Approve'}
                  </button>
                  <button
                    className={`bg-[#FF6F61] hover:bg-[#FF9A8B] text-white px-4 py-2 rounded-md focus:outline-none ${loading === deal.deal_id ? 'opacity-50 cursor-not-allowed' : ''}`}
                    disabled={loading === deal.deal_id}
                    onClick={() => handleApproval(deal.deal_id, "rejected")}
                  >
                    {loading === deal.deal_id ? 'Processing...' : 'Reject'}
                  </button>
                </div>
              )}
            </div>
          ))
        )}
      </div>
    </div>
  );
};

export default Allsmartdeals;
