import React, { useState } from 'react';
import axiosClient from './AxiosClient';

const PopupBlog = ({ info, onClose, onUpdateBlog }) => {
  const [formData, setFormData] = useState({
    title: info.title,
    content: info.content,
    description: info.description,
    image: ''
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();

    reader.onloadend = () => {
      setFormData(prevState => ({
        ...prevState,
        image: reader.result
      }));
    };

    reader.readAsDataURL(file);
  };

  const handleSave = () => {
    const adminToken = localStorage.getItem("adminToken");

    if (!adminToken) {
      console.error("Admin token not found in localStorage.");
      return;
    }

    const formDataToSend = new FormData();
    
    if (formData.title !== info.title) {
      formDataToSend.append('title', formData.title);
    }

    if (formData.content !== info.content) {
      formDataToSend.append('content', formData.content);
    }

    if (formData.description !== info.description) {
      formDataToSend.append('description', formData.description);
    }

    if (formData.image) {
      formDataToSend.append('image', formData.image);
    }

    axiosClient.post(`/blogs/edit_blog/${info.id}`, formDataToSend, {
      headers: {
        'Authorization': `Bearer ${adminToken}`
      }
    })
    .then(response => {
      console.log('Blog updated successfully:', response.data);
      onUpdateBlog(response.data);  // Call the update function
      onClose(); // Close the popup after successful update
    })
    .catch(error => {
      console.error('Error updating blog:', error);
    });
  };

  return (
    <div className="fixed top-0 left-0 w-full h-full flex justify-center items-center bg-gray-900 bg-opacity-50">
      <div className="bg-white p-8 rounded-lg">
        <div className='text-2xl font-bold my-6'>Edit Blog</div>
        <div>
          <div className='flex font-bold'>
            <div>
              <label htmlFor="file-input">Image : </label>
              <input type="file" id='file-input' accept='image/*' onChange={handleImageChange} />
            </div>
          </div>
          <img className='w-[250px]' src={formData.image || info.imageData} alt="" />
          <div className='rounded-md bg-neutral-300 my-2 px-4'>
            <div>Title</div>
            <input type="text" name="title" value={formData.title} onChange={handleChange} />
          </div>
          <div className='bg-neutral-300 my-2 rounded-md px-4'>
            <div>Content</div>
            <textarea name="content" value={formData.content} onChange={handleChange} rows="4" />
          </div>
          <div className='bg-neutral-300 my-2 rounded-md px-4'>
            <div>Description</div>
            <textarea name="description" value={formData.description} onChange={handleChange} rows="4" />
          </div>
          <button onClick={handleSave} className="bg-blue-600 text-white py-2 px-4 rounded-md">Save</button>
          <button onClick={onClose} className="ml-4 bg-gray-400 text-white py-2 px-4 rounded-md">Cancel</button>
        </div>
      </div>
    </div>
  );
};

export default PopupBlog;
