import React, { useState, useEffect } from 'react';
import axios from 'axios';
import axiosClient from '../components/AxiosClient';

const VendorRequests = () => {
  const [requests, setRequests] = useState([]);
  const [selectedVendor, setSelectedVendor] = useState(null);
  const [requestApproved, setRequestApproved] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const adminToken = localStorage.getItem('adminToken');
        if (adminToken) {
          const config = {
            headers: {
              Authorization: `Bearer ${adminToken}`,
            },
          };

          const response = await axiosClient.post('/onboarding/get_all_onboarding/1/10', null, config);
          setRequests(response.data.data[0]);
        } else {
          console.error('Admin token not found.');
        }
      } catch (error) {
        console.error('Error fetching vendor requests:', error);
      }
    };

    fetchData();
  }, [requestApproved]);

  const handleViewDetails = (vendorId) => {
    const vendor = requests.find((vendor) => vendor.id === vendorId);
    setSelectedVendor(vendor);
  };

  const handleClosePopup = () => {
    setSelectedVendor(null);
  };

  const handleApproveRequest = async (vendorId, approve_name) => {
    try {
      const adminToken = localStorage.getItem('adminToken');
      if (!adminToken) {
        console.error('Admin token not found.');
        return;
      }

      const config = {
        headers: {
          Authorization: `Bearer ${adminToken}`,
        },
      };

      const formData = new FormData();
      formData.append('id', vendorId);
      formData.append('approve_name', approve_name);

      const response = await axiosClient.post('/onboarding/approve_onboarding', formData, config);
      console.log('Vendor request approved:', response.data);
      setRequestApproved(true);
    } catch (error) {
      console.error('Error approving vendor request:', error);
    }
  };

  useEffect(() => {
    if (requestApproved) {
      setTimeout(() => {
        window.location.reload();
      }, 2000);
    }
  }, [requestApproved]);

  return (
    <div className="relative p-4 sm:p-6 bg-gray-100 min-h-screen">
      <h2 className="text-2xl sm:text-2xl font-semibold mb-4 sm:mb-6">Vendor Onboarding Requests</h2>
      <div className= "overflow-x-auto">
      <table className="w-full bg-white rounded-lg shadow-md overflow-hidden">
        <thead>
          <tr className="bg-gray-200">
            <th className="p-6 text-left text-base sm:text-lg">Serial No.</th>
            <th className="p-6 text-left text-base sm:text-lg">Vendor Name</th>
            <th className="p-6 text-left text-base sm:text-lg">Mobile</th>
            <th className="p-6 text-left text-base sm:text-lg">Payment Status</th>
            <th className="p-6 text-left text-base sm:text-lg">Action</th>
          </tr>
        </thead>
        <tbody>
          {requests.map((request, index) => (
            <tr key={request.id} className="border-b hover:bg-gray-50 transition">
              <td className="p-6">{index + 1}</td>
              <td className="p-6">{request.vendor_name}</td>
              <td className="p-6">{request.mobile}</td>
              <td className="p-6">{request.payment_status_tracker[0].status}</td>
              <td className="p-6">
                <button
                  className="bg-blue-500 text-white px-4 py-2 sm:px-4 sm:py-2 rounded-md hover:bg-blue-600 transition"
                  onClick={() => handleViewDetails(request.id)}
                >
                  View Details
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>

      {selectedVendor && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
          <div className="bg-white p-4 sm:p-8 rounded-lg shadow-lg w-11/12 sm:max-w-2xl relative">
            <span className="absolute top-3 right-3 cursor-pointer text-gray-500" onClick={handleClosePopup}>&times;</span>
            <h2 className="text-2xl sm:text-2xl font-semibold mb-4">Vendor Details</h2>
            <div className="mb-4">
              <p className="text-lg"><strong>Vendor ID:</strong> {selectedVendor.id}</p>
              <p className="text-lg"><strong>Vendor Name:</strong> {selectedVendor.vendor_name}</p>
              <p className="text-lg"><strong>Mobile:</strong> {selectedVendor.mobile}</p>
              <p className="text-lg"><strong>Email:</strong> {selectedVendor.email}</p>
              <p className="text-lg"><strong>License No.:</strong> {selectedVendor.license_no}</p>
              <p className="text-lg"><strong>Pharmacy Name:</strong> {selectedVendor.pharmacy_name}</p>
              <p className="text-lg"><strong>Pharmacy Address:</strong> {selectedVendor.pharmacy_address}</p>
              <p className="text-lg"><strong>Pharmacy City:</strong> {selectedVendor.pharmacy_city}</p>
              <p className="text-lg"><strong>Pharmacy State:</strong> {selectedVendor.pharmacy_state}</p>
              <p className="text-lg"><strong>Pincode:</strong> {selectedVendor.pincode}</p>
              <p className="text-lg"><strong>Legal Entity Name:</strong> {selectedVendor.legal_entity_name}</p>
              <p className="text-lg"><strong>GST Number:</strong> {selectedVendor.gst_number}</p>
              <p className="text-lg"><strong>PAN Number:</strong> {selectedVendor.pan_number}</p>
              <p className="text-lg"><strong>FSSAI Number:</strong> {selectedVendor.fssai_number}</p>
              <p className="text-lg"><strong>Categories:</strong> {selectedVendor.categories}</p>
              <p className="text-lg"><strong>Account Number:</strong> {selectedVendor.account_number}</p>
              <p className="text-lg"><strong>IFSC Code:</strong> {selectedVendor.ifsc_code}</p>
              <p className="text-lg"><strong>Incorporation Type:</strong> {selectedVendor.incorporation_type}</p>
              <p className="text-lg"><strong>Payment Status:</strong> {selectedVendor.payment_status_tracker[0].status}</p>
            </div>
            <div className="mt-4 flex space-x-4">
              <button
                className="bg-green-500 text-white px-4 py-2 rounded-md hover:bg-green-600 transition"
                onClick={() => handleApproveRequest(selectedVendor.id, "Approve")}
              >
                Approve Request
              </button>
              <button
                className="bg-red-500 text-white px-4 py-2 rounded-md hover:bg-red-600 transition"
                onClick={() => handleApproveRequest(selectedVendor.id, "Disapprove")}
              >
                Disapprove Request
              </button>
              <button
                className="bg-gray-400 text-white px-4 py-2 rounded-md hover:bg-gray-500 transition"
                onClick={handleClosePopup}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default VendorRequests;
