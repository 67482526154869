import React, { useEffect, useState } from 'react';
import axios from 'axios';
import EditPopup from '../components/EditFAQ';
import AddFAQ from '../components/AddFAQ';
import { FaEdit, FaPlus } from "react-icons/fa";
import { MdDelete } from "react-icons/md";
import axiosClient from '../components/AxiosClient';

const FAQ = () => {
  const [faqs, setFaqs] = useState([]);
  const [selectedFaq, setSelectedFaq] = useState(null);
  const [showAddPopup, setShowAddPopup] = useState(false);

  useEffect(() => {
    const fetchFAQs = async () => {
      try {
        const config = {
          headers: {
            'Content-Type': 'application/json',
          },
        };
        
        const response = await axiosClient.post('/faq/get_all_faq', null, config);
        console.log('FAQs response:', response.data);
        
        if (response.data.code === 200) {
          setFaqs(response.data.data[0]);
        }
      } catch (error) {
        console.error('Error fetching FAQs:', error);
      }
    };

    fetchFAQs();
  }, []);

  const handleEditClick = (faq) => {
    setSelectedFaq(faq);
  };

  const handleClosePopup = () => {
    setSelectedFaq(null);
    setShowAddPopup(false);
  };

  const handleSaveFaq = (updatedFaq) => {
    setFaqs((prevFaqs) =>
      prevFaqs.map((faq) => (faq._id === updatedFaq._id ? updatedFaq : faq))
    );
  };

  const handleDeleteFaq = async (faqId) => {
    try {
      const token = localStorage.getItem('adminToken');
      const config = {
        headers: {
          'Content-Type': 'multipart/form-data',
          'Authorization': `Bearer ${token}`,
        },
      };

      const formData = new FormData();
      formData.append('id', faqId);

      const response = await axiosClient.post('/faq/delete_faq', formData, config);
      console.log('Delete response:', response.data);

      if (response.data.code === 200) {
        setFaqs((prevFaqs) => prevFaqs.filter((faq) => faq._id !== faqId));
      } else {
        console.error('Error deleting FAQ:', response.data.message);
      }
    } catch (error) {
      console.error('Error deleting FAQ:', error);
    }
  };

  const handleAddFaq = () => {
    setShowAddPopup(true);
  };

  return (
    <div className="p-5">
      <h1 className="text-3xl mb-5">FAQs</h1>
      <button
        onClick={handleAddFaq}
        className="text-green-600 hover:text-green-900 text-2xl mb-4 flex items-center"
      >
        <FaPlus className="mr-2" />
        Add FAQ
      </button>
      <div>
        {faqs.length > 0 ? (
          faqs.map((faq, index) => (
            <div key={faq._id} className="mb-6 p-4 border rounded-lg relative">
              <h2 className="text-xl mb-2">Question {index + 1}: {faq.question}</h2>
              <p className="mb-2">Answer: {faq.answer}</p>
              <div className="flex justify-end space-x-2">
                <button
                  onClick={() => handleEditClick(faq)}
                  className="text-gray-600 hover:text-gray-900 text-2xl"
                >
                  <FaEdit />
                </button>
                <button
                  onClick={() => handleDeleteFaq(faq._id)}
                  className="text-red-600 hover:text-red-900 text-2xl"
                >
                  <MdDelete />
                </button>
              </div>
            </div>
          ))
        ) : (
          <p>Loading FAQs...</p>
        )}
      </div>
      {selectedFaq && (
        <EditPopup
          faq={selectedFaq}
          onClose={handleClosePopup}
          onSave={handleSaveFaq}
        />
      )}
      {showAddPopup && (
        <AddFAQ onClose={handleClosePopup} />
      )}
    </div>
  );
};

export default FAQ;
