import React, { useEffect, useState } from 'react';
import axiosClient from '../components/AxiosClient';

const SmartChampPage = () => {
  const [smartChamps, setSmartChamps] = useState([]);
  const [sortOrder, setSortOrder] = useState('asc');
  const [currentPage, setCurrentPage] = useState(1); // Track the current page
  const [totalPages, setTotalPages] = useState(1); // Track total pages for pagination
  const [deletingSmartChampId, setDeletingSmartChampId] = useState(null);
  const [selectedSmartChamp, setSelectedSmartChamp] = useState(null);
  const [showPopup, setShowPopup] = useState(false);
  const [showDetailsPopup, setShowDetailsPopup] = useState(false);
  const [actionSmartChampId, setActionSmartChampId] = useState(null); // Track which smart champ is being acted upon
  const [actionText, setActionText] = useState(''); // Track the entered action
  const [showActionPopup, setShowActionPopup] = useState(false); // Show/hide action popup modal

  const [actionTakenInput, setActionTakenInput] = useState('');
  const adminToken = localStorage.getItem('adminToken');



  // Fetch smart champs from the API
  const fetchSmartChamps = async () => {
   

        // Axios POST request configuration
    const config = {
          headers: {
            Authorization: `Bearer ${adminToken}`, // Assuming your API expects Bearer token
          },
        };
    try {
      const response = await axiosClient.post(`/smartchamp/get_smartchamp/all/${sortOrder}/${currentPage}/${10}`,null, config);
      setSmartChamps(response.data.data[0].alliances);
      setTotalPages(response.data.data[0].total_pages); // Assume total_pages is returned in the response
    } catch (error) {
      console.error('Error fetching smart champs', error);
    }
  };

  // Fetch smart champs whenever sortOrder or currentPage changes
  useEffect(() => {
    fetchSmartChamps();
  }, [sortOrder, currentPage]);

  // Handle sorting
  const handleSort = () => {
    setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
  };

  // Handle deleting a smart champ
  const handleDelete = async (id) => {
    try {
      await axiosClient.post(`/smartchamp/delete_smart_champ/${id}`);
      setSmartChamps(smartChamps.filter((sc) => sc._id !== id)); // Remove the deleted smart champ
      setShowPopup(false);
    } catch (error) {
      console.error('Error deleting smart champ', error);
    }
  };

  // Confirm delete popup
  const confirmDelete = (id) => {
    setDeletingSmartChampId(id);
    setShowPopup(true);
  };

  // Handle viewing smart champ details
  const handleViewDetails = async (id) => {
    try {
      const response = await axiosClient.post(`/smartchamp/search/${id}`);
      setSelectedSmartChamp(response.data.data[0]); // Store the smart champ details
      setShowDetailsPopup(true); // Open the details popup
    } catch (error) {
      console.error('Error fetching smart champ details', error);
    }
  };

  // Handle action popup
  const handleActionPopup =  (id) => {
    setActionSmartChampId(id);
    setShowActionPopup(true);

  };

   // Handle the action input change
   const handleActionInputChange = (e) => {
    setActionTakenInput(e.target.value);
  };

   // Handle save action
   const handleActionTaken = async () => {
    console.log(`Action for smart champ ID ${actionSmartChampId}: ${actionTakenInput}`);
    setShowActionPopup(false);
    try {
      const formdata=new FormData()
      formdata.append("text", actionTakenInput)
      await axiosClient.post(`smartchamp/smartchamp_action/${actionSmartChampId}`, formdata, {
        headers: {
          Authorization: `Bearer ${adminToken}`,
        },
      });
      setSortOrder("asc")
      alert('Action added successfully!');
    } catch (error) {
      console.error('Error adding action:', error);
      alert('Failed to add action.');
    }
    setActionTakenInput(''); // Clear the input box
  };

  // Save action
  const saveAction = () => {
    console.log(`Action for smart champ ID ${actionSmartChampId}: ${actionText}`);
    setShowActionPopup(false);
    setActionText(''); // Clear the input box
  };

  // Handle pagination
  const goToPreviousPage = () => {
    if (currentPage > 1) setCurrentPage(currentPage - 1);
  };

  const goToNextPage = () => {
    if (currentPage < totalPages) setCurrentPage(currentPage + 1);
  };

  return (
    <div>
      <h1>Smart Champ</h1>
      <button className="sort-button" onClick={handleSort}>
        Sort {sortOrder === 'asc' ? 'Descending' : 'Ascending'}
      </button>

      {/* Table Format */}
      <table className="smart-champs-table">
        <thead>
          <tr>
            <th>Name</th>
            <th>Company</th>
            <th>Details</th>
            <th>Delete</th>
            <th>Query</th>
            <th>Action Taken</th>
          </tr>
        </thead>
        <tbody>
          {smartChamps.map((smartChamp) => (
            <tr key={smartChamp._id}>
              <td>{smartChamp.name}</td>
              <td>{smartChamp.company_name}</td>
              <td>
                <button
                  className="details-button"
                  onClick={() => handleViewDetails(smartChamp._id)}
                >
                  Details
                </button>
              </td>
              <td>
                <button
                  className="delete-button"
                  onClick={() => confirmDelete(smartChamp._id)}
                >
                  Delete
                </button>
              </td>
              <td>{Array.isArray(smartChamp.action) ? smartChamp.action.join(', ') : 'No queries'}</td>

              <td>
              

                <button
                  className="action-button"
                  onClick={() => handleActionPopup(smartChamp._id)}
                >
                  Add Action
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      {/* Pagination Controls */}
      <div className="pagination">
        <button
          className="page-button"
          onClick={goToPreviousPage}
          disabled={currentPage === 1}
        >
          Previous
        </button>
        <span>
          Page {currentPage} of {totalPages}
        </span>
        <button
          className="page-button"
          onClick={goToNextPage}
          disabled={currentPage === totalPages}
        >
          Next
        </button>
      </div>

      {showPopup && (
        <div className="popup">
          <p>Are you sure you want to delete this smart champ?</p>
          <div className="popup-buttons">
            <button
              className="confirm-button"
              onClick={() => handleDelete(deletingSmartChampId)}
            >
              Yes
            </button>
            <button
              className="cancel-button"
              onClick={() => setShowPopup(false)}
            >
              No
            </button>
          </div>
        </div>
      )}

      {showDetailsPopup && selectedSmartChamp && (
        <div className="popup">
          <h3>Smart Champ Details</h3>
          <p>
            <strong>Name:</strong> {selectedSmartChamp.name}
          </p>
          <p>
            <strong>Company:</strong> {selectedSmartChamp.company_name}
          </p>
          <p>
            <strong>Email:</strong> {selectedSmartChamp.email}
          </p>
          <p>
            <strong>Mobile:</strong> {selectedSmartChamp.mobile}
          </p>
          <p>
            <strong>Address:</strong> {selectedSmartChamp.business_address}
          </p>
          <p>
            <strong>Message:</strong> {selectedSmartChamp.message}
          </p>
          <p>
            <strong>How did you hear about us:</strong>{' '}
            {selectedSmartChamp.how_did_you_hear_about_us}
          </p>
          <button
            className="close-button"
            onClick={() => setShowDetailsPopup(false)}
          >
            Close
          </button>
        </div>
      )}

      {showActionPopup && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
          <div className="bg-white p-5 rounded shadow-lg w-1/3">
            <h2 className="text-xl mb-4">Add Action Taken</h2>
            <input
              type="text"
              value={actionTakenInput}
              onChange={handleActionInputChange}
              placeholder="Enter action taken"
              className="border w-full p-2 mb-4"
            />
            <button
              className="bg-green-500 text-white px-4 py-2 rounded mr-2"
              onClick={handleActionTaken}
            >
              Submit
            </button>
            <button
              className="bg-gray-500 text-white px-4 py-2 rounded"
              onClick={() => setShowActionPopup(false)}
            >
              Cancel
            </button>
          </div>
        </div>
      )}

      <style jsx>{`
        .smart-champs-table {
          width: 100%;
          border-collapse: collapse;
          margin-top: 20px;
        }

        .smart-champs-table th,
        .smart-champs-table td {
          border: 1px solid #ddd;
          padding: 8px;
          text-align: center;
        }

        .smart-champs-table th {
          background-color: #f4f4f4;
          font-weight: bold;
        }

        .sort-button,
        .details-button,
        .delete-button,
        .action-button {
          background-color: #FB8802;
          color: white;
          padding: 6px 12px;
          border: none;
          cursor: pointer;
          border-radius: 4px;
          margin: 5px;
        }

        .popup {
          position: fixed;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          display: flex;
          align-items: center;
          justify-content: center;
          background: rgba(0, 0, 0, 0.5);
        }

        .popup-buttons button {
          background-color: #FB8802;
          color: white;
          padding: 8px 16px;
          margin-top: 10px;
          border-radius: 4px;
          border: none;
        }
      `}</style>
    </div>
  );
};

export default SmartChampPage;
