import React, { useState, useContext } from 'react';
import axiosClient from '../components/AxiosClient';
import { useNavigate } from 'react-router-dom';
import adminContext from "../context/admin/adminContext";
import { AiOutlineLogin } from 'react-icons/ai'; // Import an icon

// AdminLogin Component
const AdminLogin = () => {
    const [adminMobile, setAdminMobile] = useState('');
    const [adminOTP, setAdminOTP] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);
    const navigate = useNavigate();
    const { setExpiredToken } = useContext(adminContext);

    const handleAdminSubmit = async (e) => {
        e.preventDefault();
        await handleSubmit('admin', adminMobile, adminOTP);
    };

    const handleSubmit = async (loginType, mobile, otp) => {
        setIsLoading(true);
        setError(null); // Reset error state
        try {
            const formDataToSend = new FormData();
            formDataToSend.append('mobile', mobile);
            formDataToSend.append('otp', otp);

            const apiUrl = '/auth/admin_login';
            const response = await axiosClient.post(apiUrl, formDataToSend);
            console.log('Login successful:', response.data.data[0].access_token);

            if (loginType === 'admin') {
                localStorage.setItem('adminToken', response.data.data[0].access_token);
            }

            setTimeout(() => {
                navigate('/admin');
            }, 1000);
        } catch (error) {
            console.error('Error logging in:', error);
            setError('Login failed. Please check your mobile number and OTP.');
        }
        setIsLoading(false);
    };

    return (
        <div className="min-h-screen flex justify-center items-center relative bg-cover"
            style={{
                backgroundImage: "url('/back.jpg')",
                backgroundPosition: "-50px center"
            }}
        >
            {/* Dark overlay */}
            <div className="absolute inset-0 bg-black opacity-70 z-0"></div>

            {/* Logo in Background - Top Left */}
            <div className="absolute top-5 left-1 z-10 flex items-center">
                <img 
                    src="/l4.png" 
                    alt="Company Logo" 
                    className="w-16 h-16 mr-3"
                />
                <div>
                    <h1 className="text-3xl font-bold text-white -mt-5 -ml-2">SmartPills</h1>
                    <p className="text-xs text-white -mt-1 -ml-6">The smartest way to buy medicine</p>
                </div>
            </div>

            {/* Loader and Image in Center of the Screen */}
            {isLoading && (
                <div className="absolute inset-0 z-30 flex flex-col items-center justify-center">
                    <div className="relative flex items-center justify-center">
                        <div className="loader" style={{
                            width: '80px',
                            height: '80px',
                            border: '8px solid rgba(255, 255, 255, 0.8)', // Mostly white with transparency
                            borderTop: '8px solid orange',
                            borderRadius: '50%',
                            animation: 'spin 2s linear infinite', // Slower spin
                        }} />
                        <img 
                            src="loader.png" // Static loader image path
                            alt="Loading..." 
                            style={{
                                width: '60px',
                                height: '60px',
                                position: 'absolute',
                                top: '50%',
                                left: '50%',
                                transform: 'translate(-50%, -50%)',
                            }} 
                        />
                    </div>
                </div>
            )}

            {/* Admin Login Form */}
            <div className={`relative z-20 bg-white p-8 rounded-lg shadow-lg w-full max-w-sm transition-opacity duration-300 ${isLoading ? 'opacity-10' : 'opacity-100'}`}>
                <form onSubmit={handleAdminSubmit}>
                    <h2 className="text-2xl font-semibold mb-4 text-center text-orange-500">Admin Login</h2>
                    {error && <p className="text-red-500 text-center mb-4">{error}</p>} {/* Error message */}
                    <div className="mb-4">
                        <label htmlFor="adminMobile" className="block mb-2 text-gray-700">Mobile Number</label>
                        <input
                            type="text"
                            id="adminMobile"
                            value={adminMobile}
                            onChange={(e) => setAdminMobile(e.target.value)}
                            className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:border-orange-500 transition duration-200 ease-in-out"
                            required
                        />
                    </div>
                    <div className="mb-4">
                        <label htmlFor="adminOTP" className="block mb-2 text-gray-700">OTP</label>
                        <input
                            type="text"
                            id="adminOTP"
                            value={adminOTP}
                            onChange={(e) => setAdminOTP(e.target.value)}
                            className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:border-orange-500 transition duration-200 ease-in-out"
                            required
                        />
                    </div>
                    <button
                        type="submit"
                        className={`flex items-center justify-center w-full px-4 py-2 rounded-md text-white transition duration-200 ease-in-out 
                                    ${isLoading ? 'bg-orange-400 transform scale-105 cursor-not-allowed' : 'bg-orange-500 hover:bg-orange-600 focus:bg-orange-600'}`}
                        disabled={isLoading}
                    >
                        {isLoading ? (
                            <div className="relative flex items-center justify-center">
                                <AiOutlineLogin className="mr-2" />
                                Admin Login
                            </div>
                        ) : (
                            <>
                                <AiOutlineLogin className="mr-2" />
                                Admin Login
                            </>
                        )}
                    </button>
                    <p className="mt-4 text-center text-gray-600">
                        <a href="#" className="text-orange-500 hover:underline">Forgot Password?</a>
                    </p>
                </form>
            </div>
        </div>
    );
};

export default AdminLogin;
