import React from 'react';

const ProductFormFields = ({ formData, handleChange }) => {
  return (
    <>
      {Object.keys(formData).map((key) => {
        if (key !== 'image') {
          return (
            <div key={key}>
              <label className="block mb-1 capitalize">{key.replace(/_/g, ' ')}</label>
              <input
                type="text"
                name={key}
                value={formData[key]}
                onChange={handleChange}
                className="w-full px-3 py-2 border border-gray-300 rounded focus:outline-none"
              />
            </div>
          );
        }
        return null;
      })}
    </>
  );
};

export default ProductFormFields;
