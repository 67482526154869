import React, { useState } from 'react';
import axios from 'axios';
import axiosClient from './AxiosClient';

const AddBlogPopup = ({ onClose ,onAddNewBlog}) => {
  const [formData, setFormData] = useState({
    title: '',
    content: '',
    description: '',
    image: ''
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    setFormData(prevState => ({
      ...prevState,
      image: file
    }));
  };

  const handleSubmit = (e) => {
    const adminToken = localStorage.getItem("adminToken");
        
    // Check if token exists
    if (!adminToken) {
      console.error("Admin token not found in localStorage.");
      return;
    }
    e.preventDefault();
  
    const formDataToSend = new FormData();
    formDataToSend.append('title', formData.title);
    formDataToSend.append('content', formData.content);
    formDataToSend.append('description', formData.description);
    formDataToSend.append('image', formData.image);
  
    const config = {
      headers: {
        Authorization: `Bearer ${adminToken}`,
        "Content-Type": "multipart/form-data",
      },
    };
    axiosClient.post('/blogs/create_blog', formDataToSend, config)
      .then(response => {
        const { title, content, description, id, image_url } = response.data; // Destructure response data
        console.log('Blog created successfully:', response.data);
        onAddNewBlog({
          title,
          content,
          description,
          id,
          imageData: image_url  // Map the image URL properly
        });
        onClose();
      })
      .catch(error => {
        console.error('Error creating blog:', error);
      });
  };
  

  return (
    <div className="fixed top-0 left-0 w-full h-full flex justify-center items-center bg-gray-900 bg-opacity-50">
      <div className="bg-white p-8 rounded-lg">
        <div className="text-2xl font-bold mb-6">Add New Blog</div>
        <form onSubmit={handleSubmit}>
          <div className="mb-4">
            <label htmlFor="image" className="block font-bold mb-2">Image:</label>
            <input type="file" id="image" name="image" onChange={handleImageChange} />
          </div>
          <div className="mb-4">
            <label htmlFor="title" className="block font-bold mb-2">Title:</label>
            <input type="text" id="title" name="title" value={formData.title} onChange={handleChange} />
          </div>
          <div className="mb-4">
            <label htmlFor="content" className="block font-bold mb-2">Content:</label>
            <textarea id="content" name="content" value={formData.content} onChange={handleChange} rows="4" />
          </div>
          <div className="mb-4">
            <label htmlFor="description" className="block font-bold mb-2">Description:</label>
            <textarea id="description" name="description" value={formData.description} onChange={handleChange} rows="4" />
          </div>
          <button type="submit" className="px-4 py-2 bg-blue-500 text-white rounded-md">Submit</button>
          <button onClick={onClose} className="px-4 py-2 ml-2 bg-red-500 text-white rounded-md">Cancel</button>
        </form>
      </div>
    </div>
  );
};

export default AddBlogPopup;
