import React, { useState, useEffect } from 'react';
import { RiPencilFill } from "react-icons/ri";
import { MdDelete } from "react-icons/md";
import axiosClient from '../components/AxiosClient';
import PopupBlog from '../components/PopupBlog';
import AddBlogPopup from '../components/AddBlogPopup';

const Blogs = () => {
  const [blogs, setBlogs] = useState([]);
  const [popupInfo, setPopupInfo] = useState(null);
  const [isAddPopupOpen, setIsAddPopupOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const openPopup = (info) => {
    setPopupInfo(info);
  };

  const closePopup = () => {
    setPopupInfo(null);
  };

  const openAddPopup = () => {
    setIsAddPopupOpen(true);
  };

  const closeAddPopup = () => {
    setIsAddPopupOpen(false);
  };

  // Date formatting function
  const handleDate = (date) => {
    const parsedDate = new Date(date);
    if (isNaN(parsedDate)) {
      console.error("Invalid Date:", date);
      return "NAN"; // Handle invalid date
    }
    return parsedDate.toLocaleDateString(); // Return formatted date
  };

  useEffect(() => {
    const adminToken = localStorage.getItem("adminToken");
    if (!adminToken) {
      console.error("Admin token not found in localStorage.");
      return;
    }

    axiosClient.post('/blogs/get_blogs/1/10', null, {
      headers: {
        'Authorization': `Bearer ${adminToken}`
      }
    })
      .then(response => {
        const blogsData = response.data.map(blog => ({
          id: blog.id,
          title: blog.title,
          content: blog.content,
          description: blog.description,
          imageData: blog.image_url,
          date: blog.date // Make sure the date field is part of the response
        }));
        setBlogs(blogsData);
        setIsLoading(false);
      })
      .catch(error => {
        console.error('Error fetching blogs:', error);
        setIsLoading(false);
      });
  }, []);

  const handleDelete = (id) => {
    const adminToken = localStorage.getItem("adminToken");
    axiosClient.post(`/blogs/delete_blog/${id}`, null, {
      headers: {
        'Authorization': `Bearer ${adminToken}`
      }
    })
      .then(() => {
        setBlogs(prevBlogs => prevBlogs.filter(blog => blog.id !== id));
      })
      .catch(error => {
        console.error('Error deleting blog:', error);
      });
  };

  const isImage = (url) => /\.(jpg|jpeg|png|gif|bmp|svg)$/i.test(url);
  const isPDF = (url) => /\.pdf$/i.test(url);

  const handleBlogUpdate = (updatedBlog) => {
    setBlogs(prevBlogs =>
      prevBlogs.map(blog => (blog.id === updatedBlog.id ? updatedBlog : blog))
    );
  };

  const handleAddNewBlog = (newBlog) => {
    setBlogs(prevBlogs => [...prevBlogs, newBlog]);
  };

  return (
    <div className="rounded-xl p-2 relative shadow-lg">
      <div className="flex flex-col sm:flex-row justify-between px-6 sm:px-10 pt-8 items-center gap-4 sm:gap-0">
        <div className="text-2xl font-bold">All Blogs</div>
        <button
          onClick={openAddPopup}
          className="bg-blue-600 text-white py-2 px-4 rounded-md hover:bg-blue-700 transition"
        >
          Add Blogs
        </button>
      </div>
      <hr className="mt-2" />
      {isLoading ? (
        <div className="px-6 sm:px-8 mt-8 text-center">Blogs are Loading... Please wait...</div>
      ) : (
        <div className="px-6 sm:px-8 mt-6 sm:mt-8">
          <div className="hidden sm:flex justify-between font-bold">
            <div className="w-1/4 text-center">Image</div>
            <div className="w-1/4 text-left">Title</div>
            <div className="w-1/4 text-left">Date</div> {/* Added Date column */}
            <div className="w-1/4 text-center">Edit</div>
            <div className="w-1/4 text-center">Delete</div>
          </div>
          <hr className="hidden sm:block mt-2" />
          {blogs.map(blog => (
            <div
              className="my-6 flex flex-col sm:flex-row items-start sm:items-center justify-between gap-4 sm:gap-0"
              key={blog.id}
            >
              <div className="w-full sm:w-1/4 mb-4 sm:mb-0 flex justify-center">
                {isPDF(blog.imageData) ? (
                  <iframe
                    src={blog.imageData}
                    className="w-[120px] h-[160px] sm:w-20 sm:h-auto lg:w-[100px] lg:h-auto object-contain"
                    title={blog.title}
                  >
                    This browser does not support PDFs. Please download the PDF to view it:
                    <a href={blog.imageData}>Download PDF</a>
                  </iframe>
                ) : (
                  <img
                    className="w-[120px] h-[160px] sm:w-20 sm:h-[160px] lg:w-[100px] lg:h-auto object-contain rounded-md"
                    src={blog.imageData}
                    alt={blog.title}
                  />
                )}
              </div>
              <div className="w-full sm:w-1/4 text-left text-center sm:text-left">{blog.title}</div>
              <div className="w-full sm:w-1/4 text-left text-center sm:text-left">
                {handleDate(blog.date)} {/* Display the formatted date */}
              </div>
              <div className="w-full sm:w-1/4 flex justify-center">
                <RiPencilFill
                  className="text-2xl cursor-pointer text-blue-500"
                  onClick={() => openPopup(blog)}
                />
              </div>
              <div className="w-full sm:w-1/4 flex justify-center">
                <MdDelete
                  className="text-2xl cursor-pointer text-red-500"
                  onClick={() => handleDelete(blog.id)}
                />
              </div>
            </div>
          ))}
        </div>
      )}
      {popupInfo && <PopupBlog info={popupInfo} onClose={closePopup} onUpdateBlog={handleBlogUpdate} />}
      {isAddPopupOpen && <AddBlogPopup onClose={closeAddPopup} onAddNewBlog={handleAddNewBlog} />}
    </div>
  );
};

export default Blogs;
