import React from 'react';

const FileInput = ({ handleFileChange }) => {
  return (
    <div>
      <label className="block mb-1">Image</label>
      <input
        type="file"
        name="image"
        onChange={handleFileChange}
        className="w-full px-3 py-2 border border-gray-300 rounded focus:outline-none"
      />
    </div>
  );
};

export default FileInput;