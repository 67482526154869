import React, { useState, useEffect } from 'react';
import axiosClient from '../components/AxiosClient';

const Popup = ({ queryId, adminToken, onClose }) => {
  const [queryDetails, setQueryDetails] = useState(null);

  useEffect(() => {
    const fetchQueryDetails = async () => {
      try {
        const response = await axiosClient.post(`/help/get_help_request/${queryId}`, null, {
          headers: {
            Authorization: `Bearer ${adminToken}`,
          },
        });
        setQueryDetails(response.data);
      } catch (error) {
        console.error('Error fetching query details:', error);
      }
    };

    fetchQueryDetails();
  }, [queryId, adminToken]);

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-50 p-4">
      <div className="bg-white p-6 md:p-8 rounded-md shadow-md w-full max-w-md">
        {queryDetails ? (
          <div>
            <h2 className="text-lg font-semibold mb-4">Query Details</h2>
            <p><strong>ID:</strong> {queryDetails.id}</p>
            <p><strong>Email:</strong> {queryDetails.email}</p>
            <p><strong>Sender ID:</strong> {queryDetails.sender_id}</p>
            <p><strong>Query:</strong> {queryDetails.query}</p>
            <p><strong>Resolved:</strong> {queryDetails.resolve.status ? 'Yes' : 'No'}</p>
            <p><strong>Created On:</strong> {queryDetails.created_on}</p>
          </div>
        ) : (
          <p>Loading query details...</p>
        )}
        <button
          className="bg-blue-500 text-white px-4 py-2 rounded-md mt-4 w-full sm:w-auto"
          onClick={onClose}
        >
          Close
        </button>
      </div>
    </div>
  );
};

const AddActionPopup = ({ queryId, adminToken, onClose }) => {
  const [actionMessage, setActionMessage] = useState('');

  const handleSubmit = async () => {
    try {
      const formdata=new FormData()
      formdata.append("text", actionMessage)
      await axiosClient.post(`/help/help_action/${queryId}`, formdata, {
        headers: {
          Authorization: `Bearer ${adminToken}`,
        },
      });
      alert('Action added successfully!');
      onClose();
    } catch (error) {
      console.error('Error adding action:', error);
      alert('Failed to add action.');
    }
  };

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-50 p-4">
      <div className="bg-white p-6 md:p-8 rounded-md shadow-md w-full max-w-md">
        <h2 className="text-lg font-semibold mb-4">Add Action</h2>
        <textarea
          className="w-full border rounded-md p-2 mb-4"
          rows="4"
          placeholder="Enter your action message here..."
          value={actionMessage}
          onChange={(e) => setActionMessage(e.target.value)}
        />
        <div className="flex justify-end space-x-2">
          <button
            className="bg-gray-400 text-white px-4 py-2 rounded-md"
            onClick={onClose}
          >
            Cancel
          </button>
          <button
            className="bg-blue-500 text-white px-4 py-2 rounded-md"
            onClick={handleSubmit}
          >
            Submit
          </button>
        </div>
      </div>
    </div>
  );
};

const Queries = () => {
  const [queries, setQueries] = useState([]);
  const [filteredQueries, setFilteredQueries] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedQuery, setSelectedQuery] = useState(null);
  const [selectedActionQuery, setSelectedActionQuery] = useState(null);
  const [sortOption, setSortOption] = useState('date');
  const adminToken = localStorage.getItem('adminToken');

  useEffect(() => {
    const fetchQueries = async () => {
      try {
        const response = await axiosClient.post('/help/get_all_help_requests/1/10', null, {
          headers: {
            Authorization: `Bearer ${adminToken}`,
          },
        });
        setQueries(response.data?.data?.[0] || []);
        setFilteredQueries(response.data?.data?.[0] || []);
        setIsLoading(false);
      } catch (error) {
        console.error('Error fetching queries:', error);
        setIsLoading(false);
      }
    };

    fetchQueries();
  }, [adminToken]);

  useEffect(() => {
    const sortQueries = (queries) => {
      switch (sortOption) {
        case 'name':
          return [...queries].sort((a, b) => a.email.localeCompare(b.email));
        case 'date':
          return [...queries].sort((a, b) => new Date(b.created_on) - new Date(a.created_on));
        case 'resolved':
          return queries.filter(query => query.resolve.status === true);
        case 'unresolved':
          return queries.filter(query => query.resolve.status === false);
        case 'all':
          return queries;
        default:
          return queries;
      }
    };

    setFilteredQueries(sortQueries(queries));
  }, [queries, sortOption]);

  const handleViewDetails = (queryId) => {
    setSelectedQuery(queryId);
  };

  const handleAddAction = (queryId) => {
    setSelectedActionQuery(queryId);
  };

  const handleStatusChange = async (queryId, status) => {
    try {
      await axiosClient.post(`/help/update_status/${queryId}`, { status }, {
        headers: {
          Authorization: `Bearer ${adminToken}`,
        },
      });
      // After status update, refetch queries to get the latest data
      const response = await axiosClient.post('/help/get_all_help_requests/1/10', null, {
        headers: {
          Authorization: `Bearer ${adminToken}`,
        },
      });
      setQueries(response.data?.data?.[0] || []);
    } catch (error) {
      console.error('Error updating query status:', error);
    }
  };

  const handleClosePopup = () => {
    setSelectedQuery(null);
    setSelectedActionQuery(null);
  };

  return (
    <div className="py-4 sm:py-8">
      <div className="mb-4">
        <label htmlFor="sort" className="mr-2">Sort by:</label>
        <select
          id="sort"
          className="border p-2 rounded-md"
          value={sortOption}
          onChange={(e) => setSortOption(e.target.value)}
        >
          <option value="date">Date</option>
          <option value="name">Name</option>
          <option value="resolved">Resolved</option>
          <option value="unresolved">Unresolved</option>
          <option value="all">All</option>
        </select>
      </div>

      {isLoading ? (
        <p className="text-center">Loading queries...</p>
      ) : filteredQueries.length > 0 ? (
        <div className="overflow-x-auto sm:overflow-x-hidden">
          <div className="hidden sm:block">
            <table className="w-full border-collapse border border-gray-300 text-xs sm:text-sm">
              <thead>
                <tr className="bg-gray-200">
                  <th className="border border-gray-300 p-2">Sno.</th>
                  <th className="border border-gray-300 p-2">Query Created On</th>
                  <th className="border border-gray-300 p-2">Email</th>
                  <th className="border border-gray-300 p-2">Query</th>
                  <th className="border border-gray-300 p-2">Action Taken</th>
                  <th className="border border-gray-300 p-2">Query Status</th>
                  <th className="border border-gray-300 p-2">View Details</th>
                </tr>
              </thead>
              <tbody>
                {filteredQueries.map((query, index) => (
                  <tr key={query.id} className="hover:bg-gray-100">
                    <td className="border border-gray-300 p-2">{index + 1}</td>
                    <td className="border border-gray-300 p-2">{query.created_on ? (() => {
                      const date = new Date(query.created_on);
                      const day = String(date.getDate()).padStart(2, '0');
                      const month = String(date.getMonth() + 1).padStart(2, '0');
                      const year = date.getFullYear();
                      return `${day}/${month}/${year}`;
                    })() : ''}</td>
                    <td className="border border-gray-300 p-2">{query.email}</td>
                    <td className="border border-gray-300 p-2">{Array.isArray(query.action) ? query.action.join(', ') : 'No queries'}</td>
                    <td className="border border-gray-300 p-2">
                      <button
                        className="bg-green-500 text-white px-4 py-2 rounded-md text-xs sm:text-sm"
                        onClick={() => handleAddAction(query.id)}
                      >
                        Add Action
                      </button>
                    </td>
                    <td className="border border-gray-300 p-2">
                      <select
                        className="border p-2 rounded-md"
                        value={query.resolve.status ? 'Close' : 'Open'}
                        onChange={(e) => handleStatusChange(query.id, e.target.value === 'Open' ? false : true)}
                      >
                        <option value="Open">Open</option>
                        <option value="Close">Close</option>
                      </select>
                    </td>
                    <td className="border border-gray-300 p-2">
                      <button
                        className="bg-blue-500 text-white px-4 py-2 rounded-md text-xs sm:text-sm"
                        onClick={() => handleViewDetails(query.id)}
                      >
                        View Details
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div className="sm:hidden">
            {filteredQueries.map((query, index) => (
              <div key={query.id} className="border border-gray-300 p-4 mb-4">
                <p><strong>Sno:</strong> {index + 1}</p>
                <p><strong>Created On:</strong> {query.created_on ? (() => {
                  const date = new Date(query.created_on);
                  const day = String(date.getDate()).padStart(2, '0');
                  const month = String(date.getMonth() + 1).padStart(2, '0');
                  const year = date.getFullYear();
                  return `${day}/${month}/${year}`;
                })() : ''}</p>
                <p><strong>Email:</strong> {query.email}</p>
                <p><strong>Query Status:</strong>
                  <select
                    className="border p-2 rounded-md"
                    value={query.resolve.status ? 'Close' : 'Open'}
                    onChange={(e) => handleStatusChange(query.id, e.target.value === 'Open' ? false : true)}
                  >
                    <option value="Open">Open</option>
                    <option value="Close">Close</option>
                  </select>
                </p>
                <button
                  className="bg-blue-500 text-white px-4 py-2 rounded-md mt-2"
                  onClick={() => handleViewDetails(query.id)}
                >
                  View Details
                </button>
              </div>
            ))}
          </div>
        </div>
      ) : (
        <p className="text-center">No queries found.</p>
      )}

      {selectedQuery && (
        <Popup
          queryId={selectedQuery}
          adminToken={adminToken}
          onClose={handleClosePopup}
        />
      )}
      {selectedActionQuery && (
        <AddActionPopup
          queryId={selectedActionQuery}
          adminToken={adminToken}
          onClose={handleClosePopup}
        />
      )}
    </div>
  );
};

export default Queries;
